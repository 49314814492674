.check-with-label {
    position: absolute;
    z-index: -1;
    opacity: 0;
}

/* для элемента label связанного с .check-with-label */
.check-with-label+label {
    display: inline-flex;
    align-items: center;
    user-select: none;
}

/* создание в label псевдоэлемента  before со следующими стилями */
.check-with-label+label::before {
    content: '';
    display: inline-block;
    width: 1em;
    height: 1em;
    flex-shrink: 0;
    flex-grow: 0;
    border: 1px solid #474953;
    border-radius: 50%;
    margin-right: 0.5em;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
}

/* стили при наведении курсора на радио */
.check-with-label:not(:disabled):not(:checked)+label:hover::before {
    border-color: rgba(254, 119, 1, 1);
}

/* стили для активной радиокнопки (при нажатии на неё) */
.check-with-label:not(:disabled):active+label::before {
    background-color: rgba(254, 119, 1, 1);
    border-color: #474953;
}

/* стили для радиокнопки, находящейся в фокусе */
.check-with-label:focus+label::before {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

/* стили для радиокнопки, находящейся в фокусе и не находящейся в состоянии checked */
.check-with-label:focus:not(:checked)+label::before {
    border-color: #474953;
}

/* стили для радиокнопки, находящейся в состоянии checked */
.check-with-label:checked+label::before {
    border-color: #474953;
    background-color: rgba(254, 119, 1, 1);
}

/* стили для радиокнопки, находящейся в состоянии disabled */
.check-with-label:disabled+label::before {
    background-color: #937c20;
}





.deposits__btn:disabled {
    background: rgba(252, 213, 53, 0.8);
    cursor: not-allowed;
}
.deposits__btn {
    width: 100%;
}
.deposit-popup-window{
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(28, 28, 28, 0.49);
}

.deposit-popup{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 28%;
    height: 340px;
    border-radius: 15px;
    background: #080808;
}
.dp-pp-text{
    font-weight: bold;
    font-size: 1.5rem;
}
.MuiCircularProgress-svg{
    width: 60px;
    height: 60px;
}
.qrcode{
    width: 200px;
    height: 200px;
    padding: 10px ;
    border-radius: 10px;
    border: 3px solid white
}
.MuiCircularProgress-root{
    width: 60px !important;
    height: 60px !important;
}
html, body{
  margin: 0;
  padding:0;
  color: lavender;
}
.w-full{
  width: 100%;
}
.graph_stocks{
  height: 570px !important;
}
.tyf{
  display: none;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.portfolio-arrow{
  margin-left: 10px;
  transform: rotate(180deg);
  transition-duration: 0.5s;
}
.portfolio-arrow-active{
  transform: rotate(0deg);
}
.portfolio-title{
  display: flex;
  align-items: center;
}
.tyf-image{
  width: 30%;
  height: 400px;
  margin-bottom: 3rem;
}

.available-portfolio-container{
  display: grid;
  grid-template-columns: 1fr 1fr; /* Две равные колонки */
  gap: 35px; /* Расстояние между колонками */
  margin-top: 35px;
}

.available-portfolio-item{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background: rgba(0, 0, 0, 0.29);
  padding: 20px;
  border-radius: 15px;
  border: 3px solid gray;
}
.portfolio-item-title{
  font-size: 1.3rem;
  font-weight: 700;
  color: white;
}
.portfolio-item-got{
  font-size: 1.3rem;
  font-weight: 700;
}
.pi-green{
color: rgba(14, 203, 129, 1);
}
.pi-red{
color: #dc3545;;
}
.portfolio-stock-name{
  font-size: 1.3rem;
  font-weight: 700;
  color: gray;
}
.pis-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 7px;
  padding: 30px;
}
.mt-30{
  margin-top: 30px;
}
.portfolio-item-sub{
  margin: 0;
  color: rgba(118, 128, 143, 1);
}
.stock-container{
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.stock-container-2{
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  gap: 1rem;
}
.dep-modal-all{
  width: 100vw;
  height: 100vh;
  position: absolute;
}
.deposit-popup-window-2{
  background: rgba(18, 19, 24, 1);
  padding: 50px;
  border-radius: 20px;
  font-size: 1.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.pribyl-modal{
  margin-bottom: 50px;
  font-weight: 700;
  font-size: 1.5rem;
}
.mb-50{
  margin-bottom: 50px;
}
.portfolio-item-comment{
  width: 100%;
  text-align: center;
  margin-bottom: 0px;
}
.modal-button{
  padding: 15px 50px;
  border-radius: 10px;
}
.modal-buttons{
  display: flex;
  justify-content: space-between;
  gap: 20px;
}
.modal-podtv{
  background: rgba(254, 119, 1, 1);
  color: black;
}
.portfolio-item-title-si{
  width: 40%;
  font-size: 1rem;
  font-weight: 700;
  color: white;
}
.portfolio-item-sub-si-stock{
  width: 40%;
  margin-bottom: 20px;
  color: rgba(118, 128, 143, 1);
}
.container-ultra{
  margin: 0 0 30px 0;
  width: 100%;
  height: 100%;
}
.portfolio-item-sub-si{
  font-size: .85rem;
  text-align: right;
  margin: 0;
  width: 30%;
  color: rgba(118, 128, 143, 1);
}
.portfolio-stock-name-si{
  font-weight: 700;
  color: gray;
}
.portfolio-container-button{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.portfolio-item-subtext{
  color: gray;
  margin-bottom: 0;
  margin-top: 10px;
  font-size: .8rem;
  width: 25%;
}
.portfolio-item-subtext:last-child{
  text-align: right;
}

.portfolio-item-button{
  margin-top: 10px;
  background: rgba(254, 119, 1, 1);
  border-radius: 10px;
  color: black;
  padding: 10px;
  width: 50%;
}
.tyf-text{
  font-size: 4rem;
  color: white;
}


.loginplease{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(254, 119, 1, 1);
}
.log-btn{
  z-index: 2;
  transition: 0.5s;
  color: white;
  background: none;
  border-radius: 10px;
  border: solid 1px white;
  width: 90px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: .9rem;
}

.reg-btn{
  z-index: 2;
  transition: 0.5s;
  background: rgba(254, 119, 1, 1);
  color: #0e0e11;
  width: 130px;
  height: 40px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: .9rem;
  margin-left: 12px;
}

.reg-btn:hover{
  border: solid 2px rgba(254, 119, 1, 1);
  background: none;
  color: rgba(254, 119, 1, 1);
}
.log-btn:hover{
  color: rgba(255, 255, 255, 0.66);
  border-color: rgba(255, 255, 255, 0.66);
}

.success-text{
color: rgba(13, 203, 129, 1)!important
}
.m10{
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}
.mb-mini{
  margin-top: 0 !important;
  margin-bottom: 5px !important;
}
.mt-10{
  margin-top: 10px !important;
}
.form-control {
  display: block;
  width: 100%;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: lavender;
  background-color: #151c30;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: .375rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.selectIcon{
  height: 20px;
  margin-right: 5px;
}
.td-graph{
  align-items: center;
}
.tr-graph{
  border-bottom: rgba(79, 79, 79, 0.46) 1px solid !important;
}
body ::-webkit-scrollbar-thumb{
  background: rgba(79, 79, 79);
  border-radius: 2px;
}
.timer{
  text-align: center; font-size: 20px; font-weight: 400; margin-bottom: 0; margin-top: 20px;
}
.third{
  background-image: url(./images/bgThird.png);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  padding: 150px 0 280px;
}
.step-container{
  display: flex;
  width: 100%;
  padding: 20px 0;
  max-width: 1200px;
  margin: 0 auto;
  justify-content: space-between;
}
.step-child{
  width: 30%;
  position: relative;
}
.step-number{
  font-weight: 900;
  font-size: 120px;
  color: #58ccb6;
  font-family: HarmonyOS_Sans_Regular,Chinese Quote,-apple-system,BlinkMacSystemFont,Segoe UI,PingFang SC,Hiragino Sans GB,Microsoft YaHei,Helvetica Neue,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol !important;

}
.step-icon{
  margin-bottom: 12px;
  height: 32px;
}
.step-model{
  position: absolute;
  bottom: -57px;
  opacity: 1;
  background-color: rgba(0,0,0,.4);
  -webkit-backdrop-filter: blur(27.1828px);
  backdrop-filter: blur(27.1828px);
  border-radius: 5px;
  width: 100%;
  padding: 20px;
  color: #fff;
  z-index: 2;
}
.step-value{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.step-value-top{
  display: flex;
  flex-direction: column;
  width: calc(100% - 120px);
}
.step-text{
  font-size: 20px;
}
.step-button-active{
  background: #58ccb6;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 34px;
  min-width: 80px;
  border-radius: 5px;
  color: #fff;
  padding: 0 10px;
}
.step-button-disabled{
  background: hsla(0,0%,100%,.2);
  cursor: default;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 34px;
  min-width: 80px;
  border-radius: 5px;
  color: #fff;
  padding: 0 10px;
}
.step-button-disabled:hover{
  background: hsla(0,0%,100%,.2);
  color: #fff;
}
.step-button-active:hover{
  background: rgba(88, 204, 182, 0.73);
  color: #fff;
}
.step-describe{
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.third-title{
  display: flex;
  color: #fff;
  font-weight: 900;
  font-size: 40px;
  font-style: normal;
  font-family: HarmonyOS_Sans_Black !important;
}
.filterIconContainer{
  display: flex;
  align-items: center;
  justify-content: end;
  width: 100%;
}
.filter1{
  height: 365px !important;
}
.filterIcon1{
  background: linear-gradient(180deg,#ed6e72,#ed6e72 50%,hsla(0,0%,100%,.38) 0,hsla(0,0%,100%,.38));
  width: 18px;
  height: 14px;
  cursor: pointer;
  border-radius: 2px;
  margin-right: 5px;
}
.filterIcon2{
  background: linear-gradient(180deg,hsla(0,0%,100%,.38),hsla(0,0%,100%,.38) 50%,#58ccb6 0,#58ccb6);
  width: 18px;
  height: 14px;
  cursor: pointer;
  border-radius: 2px;
  margin-right: 5px;
}
.w390{
  width: 400px !important;
  margin-top: 0 !important;
}
.filterIcon3{
  background: linear-gradient(180deg,#ed6e72,#ed6e72 50%,#58ccb6 0,#58ccb6);
  width: 18px;
  height: 14px;
  cursor: pointer;
  border-radius: 2px;
}
body ::-webkit-scrollbar {
  background: hsla(0,0%,100%,.16);
  width: 3px;
  height: 4px;
}
#headerNoMarketNV{
  display: none !important;
}
.marketInfo{
  display: flex;
  margin-left: 5px;
  align-items: center;
}
.really_small{
  font-size: 0.7rem;
}
.text-muted2{
  color: #888888;
  font-size: 0.9rem;
}
.nav-item{
  display: flex;
  align-items: center;
}
.marketInfo__container{
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  padding-right: 10px;
  height: 100%;
  border-left: 1px solid rgba(136, 136, 136, 0.3);
}
.mt5{
  margin-top: 5px !important;
}
.dropdown-menu{
  background: #151c30;
  border: solid 1px #01081e;
  color: lavender;
}
.btn123{
  background: #01081e;
  border-radius: 5px 5px 0px 0px;
}
.bottom_right{
  display: flex;
  flex-direction: column;
}
.bottom_buttons{
  display: flex;
  width: 230px;
  margin-left: 6px;
  height: 42px;
}
.bottom_buttons2{
  display: flex;
  width: 99.9%;
  border-radius: 5px 5px 0px 0px;
  background: rgba(22, 26, 37, 0.58);
  margin-left: 1px;
}
.tbody123123{
  scrollbar-color: #58ccb6 #151c30;
  scrollbar-width: thin;
  height: 360px;
}
.dropdown-item{
  color: lavender;
}
.drop-area{
  width: 300px;
  height: 90px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}
.drop-area-active{
  border: 2px dashed lavender;
}
.successfull{
  width: 300px;
  text-align: center;
}
.notvisible{
  display: none;
}
.dropdown-item:hover{
  color: #58ccb6;
  --bs-dropdown-link-hover-bg: rgba(0, 0, 0, 0.18)
}
.dropdown-item:active{
  color: #58ccb6;
}
.modal-content{
  --bs-modal-bg: #151c30;
  --bs-modal-header-border-color: #01081e;
  --bs-modal-footer-border-color: #01081e;
}
.bg-dark
{
  background: #171b26 !important;
}
.bg-dark2
{
  background: #01081e !important;
}
main {
  width: 100%;
  color: lavender;
}
.button-table{
  background: none;
  border: none;
}
.button-table:hover{
  background: rgba(50, 53, 57, 0.46);
}
th{
  font-weight: 300 !important;
}
tbody, td, tfoot, th, thead, tr {
  border-color: inherit;
  border-style: none !important;
  border-width: 0;
  cursor: pointer;
}
td{
  line-height: 40px;
}
a, NavLink{
  text-decoration: none;
}
td {
  line-height: 25px;
}
.footer-container{
  width: 100%;
  display: flex;
  justify-content: space-around;
  padding-top: 3rem;
  padding-bottom: 3rem;
  background: #000000;
}
.footer__link, .footer__title{
  color: lavender;
}
.footer__link{
  margin-bottom: 0;
  margin-top: 1rem;
  text-align: left;
}
.YaSru{
  width: 50%;
  margin-left: 25%;
}
.h2FirstNew{
  font-weight: 900;
  font-size: 70px;
  line-height: 80px;
}
.first{
  width: 100%;
  --bs-btn-bg: rgba(46, 46, 66, 0.55);
  background-image: url("./images/bgFirst.png");
  color: lavender;
  background-repeat: no-repeat;
  -moz-background-size: 100%; /* Firefox 3.6+ */
  -webkit-background-size: 100%; /* Safari 3.1+ и Chrome 4.0+ */
  -o-background-size: 100%; /* Opera 9.6+ */
  background-size: 100%; /* Современные браузеры */
  height: calc(100vh - 66px);
}
.aside_left{
  padding-top: 10px !important;
}
.inputBuy:disabled{
  background: rgba(30, 40, 66, 0.96) !important;
}
#failBuy{
  font-size: 11px;
  color: red;
  width: 100%;
  text-align: center;
}
#failSell{
  font-size: 11px;
  color: red;
  width: 100%;
  text-align: center;
}
.failBuyActive{
  margin-top: 5px;
  margin-bottom: -21.5px !important;
}
.failSellActive{
  margin-top: 5px;
  margin-bottom: -21.5px !important;
}
.btn-primary{

  --bs-btn-color: lavender;
  --bs-btn-bg: #58ccb6;
  --bs-btn-border-color: #58ccb6;
  --bs-btn-hover-color: lavender;
  --bs-btn-hover-bg: #58ccb6;
  --bs-btn-hover-border-color: #459f8e;
  --bs-btn-focus-shadow-rgb: 49,132,253;
  --bs-btn-active-color: lavender;
  --bs-btn-active-bg: #58ccb6;
  --bs-btn-active-border-color: #459f8e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: lavender;
  --bs-btn-disabled-bg: #58ccb6;
  --bs-btn-disabled-border-color: #58ccb6;


  background: #58ccb6;
  border-color: rgba(88, 204, 182, 0.68);
}
.btn-outline-primary {
  --bs-btn-color: #58ccb6;
  --bs-btn-border-color: #58ccb6;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #58ccb6;
  --bs-btn-hover-border-color: #58ccb6;
  --bs-btn-focus-shadow-rgb: rgba(88, 204, 182, 0.39);
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #58ccb6;
  --bs-btn-active-border-color: #58ccb6;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #58ccb6;
  --bs-btn-disabled-bg: transparent;
  --bs-gradient: none;
}

.snacksuccess{
  background: rgba(0, 0, 0, 0.56) !important;
  border: 1px solid rgb(102, 191, 60) !important;
  color: rgb(102, 191, 60) !important;
  border-radius: 10px !important;
}
.snackerror{
  background: rgba(0, 0, 0, 0.8) !important;
  border: 1px solid #ff1744 !important;
  color: #ff1744 !important;
  border-radius: 10px !important;
}

.dividedTotal{
  font-size: 10px;
}
.hidden{
  display: none !important;
}
.btn-primary:hover{
  background: #459f8e;
  border-color: #459f8e;
}
.cancel__button{
  width: 80px;
  height: 25px;
  border-radius: 7px;
  border: none;
  margin-bottom: 5px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #dc3545;
  color: white;
}
.cancel__buttonDEL{
  width: 80px;
  height: 25px;
  border-radius: 7px;
  border: none;
  margin-bottom: 5px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #dc3545;
  color: white;
}
.cancel__buttonDEL:disabled{
  cursor: not-allowed;
  background: #da3e4d;
}
.btn-primary:active{
  background: #459f8e;
  border-color: rgba(88, 204, 182, 0.68);
}
.btn-outline-primary{
  color: #58ccb6;
  border-color: #58ccb6;
  font-size: 0.95rem !important;

}
.btn-outline-primary123:hover{
  background: #151c30 !important;
  border-color: #151c30 !important;
}

.btn-outline-primary:hover{
  background: #58ccb6;
  border-color: #58ccb6;
}
.btn-outline-primary123{
  --bs-btn-active-bg: #151c30 !important ;
  --bs-btn-active-border-color: #151c30 !important  ;
  border-color: #151c30 !important;
  --bs-btn-color: #58ccb6;
  --bs-btn-border-color: #151c30;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #151c30;
  --bs-btn-hover-border-color: #151c30;
  --bs-btn-focus-shadow-rgb: #151c30;
  --bs-btn-active-color: #fff;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #151c30;
  --bs-btn-disabled-bg: transparent;
  --bs-gradient: none;
}
.aviable {

}
.btn-outline-primary123:active{
  background-color: #151c30;
  --bs-btn-active-bg: #151c30;
  border-color: #151c30;
}
.fill{
  margin-top: 27px;
}
.mt3{
  margin-top: 43px !important;
}
.txt-flex{
  display: flex;
  justify-content: space-between;
}
.mt2{
  margin-top: -20px !important;
}
.aside_left_header{
  width: 300px !important;
  height: 600px !important;
}
.tbody_header{
  height: 450px !important;
}
.balance{
  margin-bottom: 35px;
}

.second{
  width: 100%;
  color: lavender;
  height: 750px;
}
.search{
  background: url(./images/search.svg) no-repeat center left 10px !important;
  background-size: auto 50% !important;
  padding-left:35px;
}
.mt{
  margin-top: 19px;
}
.percents{
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}
.percents__button:hover{
  color: #b0b0b0;
  background: rgba(28, 33, 47, 0.78);
}

.percents__button{
  height: 20px;
  width: 22%;
  font-size: 12px;
  color: #888888;
  background: #1C1D2FFF;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.13);
}
.fail{
  font-size: 13px;
  height: 25px;
}
.frame{
  width: 900px;
  height: 300px;
}
.second__60{
  width: 65%;
  margin-left: 20%;
  padding-top: 120px;
  display: flex;
  justify-content: space-between;
}
.graphs{
  width: 50%;
  margin-left: 25%;
  margin-top: 150px;
}
.no_fee_btc_image{
  width: 45%;
}

.table > :not(caption) > * > * {
  padding: .5rem .5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 14px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}

.crypto_icon{
  height: 40px;
  padding: 5px;
}
.crypto_name{
  padding-left: 10px;
  padding-right: 10px;
}
.TH{
  display: flex;
  align-items: center;
}
.vac{
  vertical-align: center;
}
.table{
  border-bottom: none !important;
  box-shadow: none !important;
}
.second_text{
  padding-top: 80px;
}
.centerFirst{
  padding-top: 10% !important;
}
.rdfngrjdbfjk{
  cursor: pointer;
}
.rdfngrjdbfjk:hover{
  color: rgba(254, 119, 1, 1);
}
.trade-info{
  position: absolute;
  z-index: 1;
  bottom: 0;
  width: 100%;
  min-height: 125px;
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  grid-auto-flow: column dense;
  grid-template-rows: 125px;
  grid-gap: 0 2px;
  gap: 0 2px;
}
@font-face {
  font-family: 'HarmonyOS_Sans_Black';
  src: url('./fonts/HarmonyOS_Sans_Black.ttf')  format('truetype');
}
@font-face {
  font-family: 'HarmonyOS_Sans_Regular';
  src: url('./fonts/HarmonyOS_Sans_Regular.ttf')  format('truetype');
}
.item-bigtext{
  font-size: 28px;
  line-height: 39px;
  font-family: HarmonyOS_Sans_Black !important;
}
.item-price{
  font-family: HarmonyOS_Sans_Black !important;
  font-weight: 900;
  font-size: 14px;
  color: #fff;
  line-height: 20px;
}
.base-text{
  font-family: HarmonyOS_Sans_Black !important;
  font-weight: 900;
  font-size: 14px;
  color: #fff;
  line-height: 20px;
}
.trade-item-sales{
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #fff;
  font-family: HarmonyOS_Sans_Regular,Chinese Quote,-apple-system,BlinkMacSystemFont,Segoe UI,PingFang SC,Hiragino Sans GB,Microsoft YaHei,Helvetica Neue,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol !important;
}
.trade-item{
  background-color: hsla(0,0%,100%,.05);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: 20px 0 20px 20px;
  box-sizing: border-box;
  justify-content: space-between;
}
.trade-item:hover{
  background-color: hsla(0,0%,100%,.1);
}
.second-title{
  font-style: normal;
  font-weight: 900;
  font-size: 40px;
  text-align: center;
  margin: 140px auto 45px;
}

.text-center{
  padding-top: 40px;
}
.text-align-center{
  text-align: center;
}
.first__input{
  margin-top: 10px;
  width: 550px;
  align-items: center;
  display: flex;
}
.first__input_window{
  width: 62%;
  height: 60px;
  background: #151c30;
  border: none;
  border-radius: 5px;
  padding: 10px;
  color: lavender;

}
input{
  color: lavender !important;
  border-color: rgba(0, 0, 0, 0.13) !important;
}
.form-select{
  background: rgba(32, 30, 47, 0.7) !important;
  color: lavender !important;
  border-color: rgba(0, 0, 0, 0.13) !important;
}
.first__input_button{
  border-radius: 5px;
  width: 34%;
  height: 60px;
  margin-left: 4%;
  border: none;
  background: #58ccb6;
  color: white;
  font-size: 16px;
  font-weight: 500;
}
.24h{
     text-align: left !important;
   }
.no_mp{
  margin: 0 !important;
  padding: 0 !important;
}
.text-end{
  padding-right: 10px!important;
}
.center{
  display: flex;
  justify-content: center;

}
.pb10{
  margin-top: -10px;
}

.banner__image{
  height: 100px;
  width: 200px;
}
tr{
  --bs-table-hover-color: rgba(230, 230, 250, 0.76);
}
.btn-outline-primary {
  --bs-btn-focus-shadow-rgb: none;
}
.obertka{
  display: flex;
  justify-content: center;
}
.market{
  margin-top: 10px;
  width: 100%;
  display: flex;
  margin-bottom: 5px;
  margin-right: 10px;
  margin-left: 5px;
}
.mw16{
  --bs-dropdown-min-width: 16rem !important;
  top: 120% !important;
}
.aside_left{
  flex-shrink: 0;
  width: 100%;
  height: 500px;
  background: #151c30;
  border-radius: 5px;
}
.avg{
  margin-top: 10px;
  margin-bottom: 10px;
}
.aside_right{
  margin-left: 5px;
  max-width: 250px;
  margin-right: 0;
  height: 505px;
  background: #161a25;
  border-radius: 5px;
}
.notAuthed{
  text-align: center;
}
.aside_right_bottom{
  padding-top: 0 !important;
  margin-left: 5px;
  max-width: 250px;
  margin-right: 0;
  background: #151c30;
  border-radius: 0px 0px 5px 5px;
}
.navbar-brand{
  color: #58ccb6;
  font-weight: 500;
}
.market__container{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: space-between;
}
.market__main{
  width: 100%;
  height: 100%;
  margin-bottom: 5px;
  background: #161a25 ;
  border-radius: 5px;
}
.accountIcon{
  margin-right: 7px;
}
.t-blue{
  color: #58ccb6;
}
.t-blue:hover{
  color: #45a18f;
}
.navbar-brand:hover{
  color: #45a18f;
}
.market__main_bottom{
  padding: 10px;
  width: 100%;
  height: 196px;
  background: #151c30;
  border-radius: 0px 0px 5px 5px;
}
.color-white{
  color: lavender !important;
}
.color-white:hover{
  color: rgba(230, 230, 250, 0.73) !important;
}
.table-sm > :not(caption) > * > * {
  padding: .0px .25rem;
}
.icon_hui{
  height: 35px;
}
.accountdrop{
  text-wrap: none;
  padding: 5px;
  display: flex;
  background: #F0F8FF;
  border-radius: 13px;

}
.p0{
  height: 20px;
}
.drop{
  margin-right: 0;
  margin-left: 5px;
  padding-right: 0;
}
.dropdown-toggle3::after{
  border-top: none !important;
  width: 10px !important;
}
.dropdown-toggle3{
  border-top: none !important;
  width: 20px !important;
}
.ts{
  font-size: 14px;
  color: #5E6E78;
}

.dropdown{
  display: flex;
  align-items: center;
  text-wrap: none;
  height: 40px;
  padding-left: 5px;
  padding-right: 5px;
}
.UserCard{
  color: #000;
}
.tbody_scroll{
  overflow: auto !important;
  height: 700px !important;
  width: 100%;
}
.aside_left, .aside_right, .aside_right, .aside_right_bottom{
  padding: 20px;
}

.nav-link{
  color: rgba(254, 119, 1, 1);
}
.dfkjhgkd{
  margin-left: 5px;
  margin-right: 5px;
}
.nav-link:hover{
  color: #8d6e00;
}
.text-black{
  color: #58ccb6 !important;
}
.aside_title{
  font-size: 15px;
  font-weight: 400;
}
.tr{
  font-size: 11px;
}
.text-muted{
  color: rgba(230, 230, 250, 0.8) !important;
}
.table-dark{
  --bs-table-bg: rgba(33, 37, 41, 0);
  --bs-table-hover-bg: rgba(50, 53, 57, 0.46);
}
.btn-eth-usdt-btc{
  margin-bottom: 10px;
  margin-top: 10px !important;
}
.btn-group{
  margin-top: 10px;
  width: 100%;
}
.btn-group2{
  width: 100%;
}

.fiks{
  width: 100%;
  margin: 0;
  height: 100%;

}

tbody {
  display: block;
  height: 700px;
  overflow: auto;
  scrollbar-color: #58ccb6 #151c30;
  scrollbar-width: thin;
}
.tbody1{
  height: 480px;
}
.tbody {
  display: block;
  height: 420px;
  overflow: auto;
}
.tbody3 {
  display: block;
  height: 167px;
  scrollbar-color: #58ccb6 #151c30;
  scrollbar-width: thin;
  overflow: auto;
}
.mockup{
  width: 100%;
  max-width: 800px;
}
.last_bottom{
  height: 700px;
  background: #0e121f;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.btn-outline-primary{
  --bs-btn-active-bg: #58ccb6 ;
  --bs-btn-active-border-color: #58ccb6 ;
}
.navbar-nav{
  --bs-nav-link-hover-color: rgba(88, 204, 182, 0.69)
}
.signup{
  width: 10%;
  background: #58ccb6;
  border-color: #58ccb6;
  font-weight: 500;
  margin-top: 20px;
  color: white;
}
.signup:hover{
  width: 10%;
  background: rgba(88, 204, 182, 0.78);
  border-color: #58ccb6;
  font-weight: 500;
  color: white;
}
.start{
  padding-top: 40px;
  padding-bottom: 10px;
}
.mt10{
  margin-top: 10px;
}
.closed{
  display: none !important;
}
.openNotVisible{
  display: none !important;
}
.tbody2 {
  display: block;
  height: 140px;
  overflow: auto;
}
thead, tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
  color: lavender;
}
.userOrdersSection{
  font-size: 14px !important;
}
@media (min-height: 1000px) {
  .aside_right{
    height: 100%;
  }
  .obertka{
    height: calc(100vh - 76px) !important;
  }
  .tbody3{
    height: calc(50vh - 303px) !important;
  }
  .filter1{
    height: calc(100vh - 581px) !important;
  }
  .tbody{
    height: calc(100vh - 541px) !important;
  }
}
@media (max-width: 1600px) {
  .tr{
    font-size: 10px;
  }
}
@media (max-width: 768px){
  .second__60{
    flex-direction: column;
  }
  .accountLogout{
    margin-right: auto;
  }
  .marketInfo{
    display: none;
  }
  .navbar-collapse{
    display: flex;
    flex-direction: column-reverse;
  }
  .no_fee_btc_image{
    width: 100%;
  }
  .signup{
    width: 50%;
    margin-left: 25%;
  }
  .userOrdersSection{
    font-size: 11px !important;
  }
  .dateStampOrder{
    display: none;
  }
  .market__main_bottom{
    margin-top: 10px;
  }
  .tbody2{
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }
  .market{
    display: flex;
    flex-direction: column;
  }
  .aside_left{
    width: 97%;
    height: auto;
    margin-bottom: 10px;
  }
  .aside_right{
    margin-left: 0;
    margin-top: 10px;
    max-width: none;
    flex-shrink: 0;
    height: auto;
    margin-bottom: 10px;
  }
  .aside_right_bottom{
    margin-left: 0;
    max-width: none;
  }
  .market__container{
    width: 98%;
    margin-left: 2%;
  }
  tbody{
    height: auto;
  }
  th{
    font-size: 10px !important;
  }
  h5 {
    font-size: 15px !important;
  }
  td {
    font-size: 11px !important;
  }
  .aside_title{
    font-size: 14px !important;
    text-align: center;
  }
  .market__main{
    width: 1000px;
  }
  .logout{
    text-align: left;
  }
  .quantity{
    text-align: center;
  }
  .dropdown{
    justify-content: space-between !important;
    width: 100%;
  }
}


@media (orientation: portrait) {
  .root-option{
    display: none;
  }
  .tyf{
    display: flex;
  }
}
.comps__bar{
    width: 420px;
    background: #1b1b1b;
    border-radius: 10px;
    margin-bottom: auto;

}
.comps__contact{
    height: calc(100vh - 82px);
    min-height: 400px;
}
.comps__body{
    width: 85%;
    max-width: 1200px;
    padding: 15px 0;
    min-height: calc(100vh - 82px);
    display: flex;
    justify-content: space-between;
    align-items: start;


}
.foot_container{
    width: 100%;
    background: color(srgb 0.0784 0.0784 0.0784);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.comps__content{
    margin-left: 24px;
    border-radius: 10px;
    color: white;
    background: #1b1b1b;
    line-height: 20px;
    padding: 30px 30px;
}
.comps__content_new{
    margin-left: 24px;
    border-radius: 10px;
    color: white;
    /*background: #1b1b1b;*/
    line-height: 20px;
    padding: 0px 30px;
}

.bar__item{
    padding: 15px 20px;
    display: flex;
    align-items: center;
}

.bar__item:first-child{
    border-radius: 5px 5px 0px 0px;
}
.bar__item:last-child{
    border-radius: 0px 0px 5px 5px;
}


.bar__item:hover{
    background: #343434;
    border-left: 2px solid rgba(254, 119, 1, 1);
    color: white;
    margin-left: -2px;
}
.bar__item-active{
    background: #343434;
    border-left: 2px solid rgba(254, 119, 1, 1);
    color: white;
    margin-left: -2px;
}
.bar__text{
    vertical-align: middle;
    margin-bottom: 0;
    padding-left: 20px;
}

.spec_flex_container{
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
}
.specials_1{
    width: 85%;
    max-width: 1200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.specials_h1{
    font-size: 40px;
    font-weight: 600;
    color: white;
}
.specials_h2{
    font-size: 32px;
    color: white;
    font-weight: 500;
}
.specials_sh1{
    font-size: 32px;
    color: white;
    font-weight: 600;
}
.specials_img{
    height: 500px;
}

.specials_img2{
    width: 40%;
    padding: 0px 50px;
}

.spec_flex_col{
    display: flex;
    flex-direction: column;
    padding-left: 50px;
}

.specials_sub1{
    font-weight: 600;
    font-size: 1.1rem;
    display: flex;
    align-items: center;
}

.specials_text1{
    margin-left: 15px;
    font-size: .9rem;
}

.spec-dot{
    width: 5px;
    height: 5px;
    margin-right: 10px;
    background: rgba(254, 119, 1, 1);
}
.spec_command_card{
    border-radius: 50%;
    width: 30%;
    align-items: center;
    margin-top: 100px;
    display: flex;
    flex-direction: column;
}
.card_img{
    padding: 0px 20px;
    width: 100%;
    margin-bottom: 10px;
}

.card_name{
    font-size: 1.2rem;

}

.card_desc{
    font-size: .9rem;
    text-align: center;
}
@import url(https://fonts.googleapis.com/css?family=Inter:100,200,300,regular,500,600,700,800,900);
@charset "UTF-8";
* {
  padding: 0px;
  margin: 0px;
  border: 0px;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  min-width: 320px;
}

body {
  color: #848e9c;
  line-height: 1;
  font-family: "Arial";
  font-size: 0.875rem;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input,
button,
textarea {
  font-family: "Arial";
  font-size: inherit;
}

button {
  cursor: pointer;
  color: inherit;
  background-color: inherit;
}

a {
  color: inherit;
}

a:link,
a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

ul li {
  list-style: none;
}

img {
  vertical-align: top;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: inherit;
  font-size: inherit;
}

body {
  background: color(srgb 0.0784 0.0784 0.0784);
}
.lock body {
  overflow: hidden;
  touch-action: none;
}

.wrapper {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.wrapper > main {
  flex: 1 1 auto;
}
.wrapper > * {
  min-width: 0;
}

/*
(i) Стили будут применяться ко 
всем классам содержащим *__container
Например header__container, main__container и т.д.
Снипет (HTML): cnt
*/
[class*=__container] {
  max-width: 120rem;
  box-sizing: content-box;
  margin: 0 auto;
  padding: 0 0.9375rem;
}

[class*=-ibg] {
  position: relative;
}
[class*=-ibg] img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
}

[class*=-ibg_contain] img {
  object-fit: contain;
}

.header {
  padding: 21px 0;
}
.header__top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header__top-right {
  display: flex;
}
.header__money {
  margin-right: 45px;
  padding: 5px 15px;
  max-width: 152px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8.5px;
  color: #181a20;
  text-align: center;
  font-family: Arial;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 178.522%;
  border-radius: 5.356px;
  background: rgba(254, 119, 1, 1);
  width: 100%;
}
.header__money:hover {
  background: #f6cb1d;
}
.header__btn {
  margin-right: 18px;
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.header__btn ul {
  display: none;
  box-shadow: rgba(24, 26, 32, 0.1) 0px 0px 1px, rgba(71, 77, 87, 0.08) 0px 7px 14px, rgba(24, 26, 32, 0.08) 0px 3px 6px;
  border-radius: 8px;
  background-color: rgb(30, 35, 41);
  padding: 16px;
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 100%;
  z-index: 100;
  width: 200px;
}
.header__btn li {
  padding: 10px;
  margin-bottom: 10px;
}
.header__btn li:hover {
  background: rgba(24, 26, 32, 0.6392156863);
  border-radius: 5px;
}
.header__btn:hover ul {
  display: block;
}
.header__btn-hover {
  display: flex;
  color: #eaecef;
  font-family: Arial;
  font-size: 18.745px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  align-items: center;
}
.header__btn-hover span {
  display: flex;
}
.header__indi-box {
  display: flex;
  gap: 30px;
  margin: 0 10px;
  align-items: center;
}
.header__indi {
  position: relative;
  transition: all 0.3s ease 0s;
}
.header__indi:hover {
  transform: scale(1.1);
}
.header .count {
  position: absolute;
  right: -3px;
  top: -3px;
  border-radius: 133889.875px;
  background: #f6465d;
  min-width: 18px;
  min-height: 18px;
  padding: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #fff;
  display: block;
}

.bin__left {
  width: 100%;
  max-width: 1450px;
}
.bin__top {
  display: flex;
  align-items: center;
  padding: 13.5px 0;
}
.bin__para {
  padding-right: 30px;
  position: relative;
  border-right: 1.339px solid #252930;
}
.bin__para span {
  color: #eaecef;
  font-family: Arial;
  font-size: 25.439px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: block;

  display: flex;
  gap: 3px;
  align-items: center;
  justify-content: center;
}
.bin__para span img {
  filter: brightness(0) invert(1);
  margin-bottom: 5px;
}
.bin__para:hover .bin__pars {
  display: block;
  z-index: 100;
}
.bin__body {
  display: flex;
  justify-content: center;
}
.bin__pars {
  box-shadow: rgba(24, 26, 32, 0.1) 0px 0px 1px, rgba(71, 77, 87, 0.08) 0px 7px 14px, rgba(24, 26, 32, 0.08) 0px 3px 6px;
  border-radius: 8px;
  background-color: rgb(30, 35, 41);
  padding: 16px;
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 105%;
  width: 200px;
  display: none;
}
.bin__pars li {
  padding: 10px;
  margin-bottom: 10px;
}
.bin__pars li:hover {
  background: rgba(24, 26, 32, 0.6392156863);
  border-radius: 5px;
}
.bin__two {
  margin-right: 40px;
  margin-left: 30px;
}
.bin__t-top {
  margin-bottom: 4px;
  color: #f6465d;
  font-family: Arial;
  font-size: 20.084px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.bin__t-bottom {
  color: #eaecef;
  font-family: Arial;
  font-size: 14.728px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.bin__t-item {
  margin-right: 20px;
}
.bin__t-verh {
  margin-bottom: 5px;
  color: #848e9c;
  font-family: Arial;
  font-size: 14.728px;
  font-style: normal;
  font-weight: 400;
  line-height: 145.455%;
}
.bin__t-niz {
  color: #eaecef;
  font-family: Arial;
  font-size: 14.728px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.bin__right {
  width: 100%;
  max-width: 424px;
}
@media (max-width: 112.5em) {
  .bin__right {
    max-width: 340px;
    padding-left: 10px !important;
  }
}

.bin__wrap {
  display: flex;
  width: 100%;
}
.bin__w-left {
  max-width: 424px;
  padding: 11px 21px;
  width: 100%;
  padding-left: 0;
}
@media (max-width: 112.5em) {
  .bin__w-left {
    max-width: 340px;
    padding: 11px;
    padding-left: 0;
  }
}
.bin__l-top {
  margin-bottom: 16px;
}
.bin__search {
  width: 100%;
  margin-bottom: 16px;
  display: flex;
  border-radius: 5.388px;
  background: #2b3139;
  padding: 3px;
  gap: 5px;
}
.bin__search .input {
  flex: 1 1 auto;
  background: transparent;
  outline: none;
  color: #eaecef !important;
  display: block;
}
.bin__slider-box {
  display: flex;
  gap: 11px;
  width: 100%;
  align-items: center;
}
.bin__line {
  cursor: pointer;
}
.bin__slider {
  width: 100%;
  margin: 0 auto;
}
.bin__slide {
  color: #848e9c;
  font-family: Arial;
  font-size: 14.817px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
  text-transform: uppercase;
}
.bin__slide a:hover {
  color: rgba(254, 119, 1, 1);
}
.bin__w-chart {
  width: 100%;
  max-width: 1055px;
}
.pointer{
  cursor: pointer;
}
.oranj {
  color: rgba(254, 119, 1, 1);
}

.bin__c-top {
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;
}
.bin__c-top-item {
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.bin__c-top-item:first-child {
  justify-content: flex-start !important;
}
.bin__c-body {
  width: 100%;
}
.bin__c-line {
  display: flex;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.bin__c-line:not(:last-child) {
  margin-bottom: 15px;
}
.bin__c-name {
  width: 30%;
  color: #eaecef;
  font-family: Arial;
  font-size: 14.817px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.bin__c-name i {
  color: #848e9c;
  font-style: normal;
}
.bin__c-price {
  width: 30%;
  text-align: right;
  color: #b7bdc6;
  font-family: Arial;
  font-size: 14.817px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.bin__c-percent {
  margin-right: 5px;
  width: 30%;
  text-align: right;
  font-family: Arial;
  font-size: 14.817px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.red {
  color: #f6465d;
}

.green {
  color: #0ecb81;
}

.bin__w-grafik {
  height: 620px;
  min-width: 720px;
}
@media (max-width: 83.75em) {
  .bin__w-grafik {
    min-width: 650px;
  }
}

.bin__b-para {
  padding: 20px 30px;
  background: #1e2026;
  color: #eaecef;
  font-family: Arial;
  font-size: 18.745px;
  width: fit-content;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-top: 3px solid rgba(254, 119, 1, 1);
}
.bin__b-body {
  background: #1e2026;
  padding: 21px;
  padding-top: 0;
}
.bin__b-body .select select {
  outline: none;
  padding: 3px 2px;
  border-radius: 5px;
  background: rgba(217, 217, 217, 0.5);
  backdrop-filter: blur(8.5px);
  color: #222;
  text-align: center;
  font-family: Arial;
  font-size: 18.745px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.bin__b-body .znak {
  margin-right: auto;
  cursor: pointer;
}
.bin__b-body .prevod {
  cursor: pointer;
  transition: 0.3s;
  color: rgba(254, 119, 1, 1);
  font-family: Arial;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.bin__b-body .tabs__navigation {
  color: #848e9c;
  font-family: Arial;
  font-size: 18.745px;
  font-style: normal;
  font-weight: 400;
  line-height: 16.067px; /* 85.714% */
  display: flex;
  gap: 19px;
  align-items: center;
}
.bin__b-navi {
  padding: 16px 0;
  display: flex;
  gap: 19px;
  align-items: center;
  width: 100%;
}
.bin__b-inf {
  display: flex;
  gap: 19px;
  flex: 1 1 auto;
  align-items: center;
}

._tab-active {
  color: rgba(254, 119, 1, 1);
}

input[type=range] {
  -webkit-appearance: none;
  appearance: none;
  height: 5px;
  outline: none;
}

.bin__b-box {
  width: 100%;
  display: flex;
  gap: 40px;
}
.bin .polov {
  width: 50%;
}
.bin__b-dostup {
  margin-bottom: 13px;
  display: flex;
  align-items: center;
  gap: 20px;
}
.bin__b-dostup p {
  color: #848e9c;
  font-family: Arial;
  font-size: 16.067px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.bin__b-scr {
  display: flex;
  align-items: center;
  gap: 11px;
}
.bin__b-scr span {
  color: #eaecef;
  text-align: right;
  font-family: Arial;
  font-size: 14.728px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.deposit-popup-window{
  z-index: 3;
}
.account__item{
  z-index: 2;
}
.bin__form .button {
  border-radius: 5.356px;
  transition: 0.3s;
  background: #0ecb81;
  width: 100%;
  padding: 12px;
  text-align: center;
  color: #fff;
  text-align: center;
  font-family: Arial;
  font-size: 17.406px;
  font-style: normal;
  font-weight: 400;
  line-height: 26.778px; /* 153.846% */
}
.bin__form .button:hover {
  filter: brightness(0.9);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.form-input {
  background: #292d33;
  border: 1px solid transparent;
  border-radius: 5px;
  color: #fff;
  color: #848e9c;
  font-family: Arial;
  font-size: 17.406px;
  font-style: normal;
  font-weight: 400;
  line-height: 29.992px; /* 172.308% */
  padding: 8px;
  flex: 1 1 auto;
  margin-bottom: 16px;
}
.form-input input {
  background: transparent;
  color: #fff;
  text-align: right;
  width: 100%;
  outline: none;
  color: #fff;
  color: #eaecef;
  text-align: right;
  font-family: Arial;
  font-size: 18.745px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.form-input__label {
  color: #848e9c;
  display: flex;
  font-size: 10px;
  gap: 10px;
  justify-content: space-between;
}
.form-input__placeholder {
  color: #848e9c;
  font-family: Arial;
  font-size: 17.406px;
  font-style: normal;
  flex-shrink: 0;
  font-weight: 400;
  line-height: 29.992px; /* 172.308% */
}
.form-input__currency {
  color: #eaecef;
  text-align: right;
  font-family: Arial;
  font-size: 16.067px;
  font-style: normal;
  font-weight: 400;
  line-height: 29.992px; /* 186.667% */
}

.input-range-form {
  margin-bottom: 20px;
  width: 100%;
}

input[type=range] {
  -webkit-appearance: none;
  background: #474d57;
  cursor: pointer;
}

input[type=range]::-webkit-slider-thumb {
  background: url("../img/thumb1.svg");
  -webkit-appearance: none;
  border: none;
  width: 30px;
  background-repeat: no-repeat;
  height: 30px;
}

.polov2 input[type=range]::-webkit-slider-thumb {
  background: url("../img/thumb2.svg");
  -webkit-appearance: none;
  border: none;
  width: 30px;
  background-repeat: no-repeat;
  height: 30px;
}

.polov2 .button {
  border-radius: 5.356px;
  background: #f6465d;
}

.bin__right {
  padding-left: 21px;
}
.bin__right .bin__c-percent {
  color: #b7bdc6;
  text-align: right;
  font-family: Arial;
  font-size: 14.728px;
  font-style: normal;
  font-weight: 400;
  line-height: 26.778px; /* 181.818% */
}
.bin__right .bin__c-name {
  color: #f6465d;
  font-family: Arial;
  font-size: 14.728px;
  font-style: normal;
  font-weight: 400;
  line-height: 26.778px; /* 181.818% */
}
.bin__right .bin__c-line {
  margin-bottom: 4px;
}


.show {
  display: block;
}

.sitr {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0;
  padding-top: 10px;
}
.sitr__box{
  display: flex;
  align-items: center;
}
.svgrtd{
  transform: rotate(180deg);
}
.sitr__name {
  font-family: Arial;
  font-size: 25.439px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
@media (max-width: 90em) {
  .sitr__name {
    font-size: 20px;
  }
}
.sitr__min {
  color: #848e9c;
  font-family: Arial;
  font-size: 14.728px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.sitr__ece {
  color: #848e9c;
  font-family: Arial;
  font-size: 16.067px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: block;
  margin-left: auto;
}

.account ._tab-active {
  border-radius: 5px;
  color: rgba(254, 119, 1, 1) !important;
  background: #080808;
}

.account {
  background: #080808;
}
.account__container {
  padding-top: 80px;
}
.account__body {
  display: flex;
  gap: 30px;
  margin: 0 auto;
  justify-content: center;
}
.account__sidebar {
  padding: 40px;
  border-radius: 10px;
  background: #121318;
  max-width: 432px;
  width: 100%;
  padding-bottom: 100px;
}
@media (max-width: 93.75em) {
  .account__sidebar {
    padding: 20px;
  }
}
.account__s-top img {
  border-radius: 120px;
  background: #d8d8d8;
  margin-bottom: 20px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 125px;
  width: 100%;
}
.account__name {
  color: #fff;
  text-align: center;
  /* Heading/Mobile Typography/H4/Medium */
  font-family: Inter;
  font-size: 23px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 121.739% */
}
.account__date {
  margin-bottom: 26px;
  color: #6c7080;
  text-align: center;
  /* Paragraph/P2/Regular */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
}
.account__cont {
  width: 100%;
  max-width: 1180px;
}
@media (max-width: 90.625em) {
  .account__cont {
    max-width: 1000px;
  }
}
.account__box {
  width: 100%;
}
.account__b-top {
  display: flex;
  padding: 40px;
  border-radius: 10px;
  width: 100%;
  background: #121318;
  gap: 130px;
  align-items: center;
}
@media (max-width: 93.75em) {
  .account__b-top {
    padding: 20px;
  }
}
.account__balans {
  margin-bottom: 50px;
  color: rgba(254, 119, 1, 1);
  /* Heading/Web Typography/H5/Medium */
  font-family: Inter;
  font-size: 23px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 121.739% */
}
.account__balans-value {
  display: flex;
  align-items: center;
  gap: 40px;
  color: #fff;
  font-family: Inter;
  font-size: 55px;
  font-style: normal;
  font-weight: 900;
  line-height: 40px; /* 72.727% */
  letter-spacing: 1.1px;
}
.account__image {
  position: relative;
  display: flex;
  align-items: center;
}
.account__image img {
  position: absolute;
}
.account .tabs__title {
  width: 100%;
  display: flex;
  margin-bottom: 10px;
  align-items: center;
  gap: 20px;
  padding: 15px 20px;
  color: #6c7080;
  /* Paragraph/P2/Regular */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
}
.account .tabs__title svg {
  fill: #9295a6;
}
.account .tabs__title:hover {
  border-radius: 5px;
  color: rgba(254, 119, 1, 1) !important;
  background: #080808;
}
.account .tabs__title:hover svg {
  fill: rgba(254, 119, 1, 1) ;
}
.account ._tab-active svg {
  fill: rgba(254, 119, 1, 1) ;
}

.account__flex {
  display: flex;
  width: 100%;
  gap: 40px;
  padding: 37px 30px;
}
@media (max-width: 90.625em) {
  .account__flex {
    gap: 30px;
  }
}
.account__item {
  width: 32%;
  padding: 23px;
  border-radius: 10px;
  background: #121318;
}
@media (max-width: 90.625em) {
  .account__item {
    padding: 15px;
  }
}
.account__i-top {
  display: flex;
  align-items: center;
  gap: 23px;
  color: #fff;
  color: #fff;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  justify-content: center;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 24px;
}
@media (max-width: 90.625em) {
  .account__i-top {
    font-size: 18px;
  }
}
.account__i-value {
  color: #fff;
  font-family: Inter;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.5px;
  text-align: center;
}
@media (max-width: 90.625em) {
  .account__i-value {
    font-size: 25px;
    line-height: 150%;
  }
}

.account {
  padding-bottom: 50px;
}
.account__story {
  padding: 40px;
  border-radius: 10px;
  background: #121318;
}
.account__s-title-big {
  color: #fff;
  /* Heading/Web Typography/H5/Medium */
  font-family: Inter;
  font-size: 23px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 121.739% */
  margin-bottom: 54px;
}
.account__s-title {
  display: flex;
  align-items: center;
  gap: 4px;
  color: #6c7080;
  /* Paragraph/P1/Regular */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px; /* 121.429% */
  width: 100%;
  max-width: 153px;
  text-align: right;
  display: flex;
  align-items: center;
}
.account__s-title img {
  max-width: 7px;
}
.account__s-title span {
  display: flex;
}
.account__s-title:first-child {
  width: 100%;
  max-width: 273px;
}
@media (max-width: 90.625em) {
  .account__s-title:first-child {
    width: 230px;
  }
}
.account__s-title:last-child {
  justify-content: flex-end;
}
@media (max-width: 90.625em) {
  .account__s-title:last-child {
    width: 100px;
  }
}
.account__s-title:nth-child(3) {
  text-align: left;
}
.account__ss-top {
  display: flex;
  padding: 20px 0;
  border-top: 1px solid rgba(239, 239, 239, 0.15);
  border-bottom: 1px solid rgba(239, 239, 239, 0.15);
  margin-bottom: 20px;
  justify-content: space-between;
}
.account__s-line {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  padding: 8px 0;
  justify-content: space-between;
}
.account__s-name {
  width: 273px;
  display: flex;
  align-items: center;
  gap: 8px;
  color: #fff;
  /* Paragraph/P3/Regular */
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 133.333% */
}
@media (max-width: 90.625em) {
  .account__s-name {
    width: 170px;
  }
}
.account__s-make {
  width: 153px;
  display: flex;
  align-items: center;
  gap: 8px;
  color: #fff;
  /* Paragraph/P2/Regular */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
}
.account__s-id {
  width: 153px;
  color: #6c7080;
  /* Paragraph/P2/Regular */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
}
.account__s-summ {
  width: 153px;
  color: #fff;
  /* Paragraph/P2/Regular */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
}
@media (max-width: 90.625em) {
  .account__s-summ {
    width: 100px;
  }
}
.account__s-time {
  width: 153px;
  color: #6c7080;
  /* Paragraph/P2/Regular */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
}
.account__s-status {
  width: 153px;
  text-align: right;
  /* Paragraph/P2/Regular */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
}
@media (max-width: 90.625em) {
  .account__s-status {
    width: 120px;
  }
}

.s-red2 {
  padding: 5px 8px;
  border-radius: 4px;
  background: #080808;
  color: #f51825;
}

.s-red {
  padding: 5px 8px;
  border-radius: 4px;
  background: #080808;
  color: rgba(254, 119, 1, 1);
}

.s-green {
  padding: 5px 8px;
  border-radius: 4px;
  background: #080808;
  color: #03a66d;
}

.account__posts {
  display: flex;
  align-items: center;
  gap: 20px;
  padding-top: 20px;
  display: flex;
  align-items: center;
  gap: 20px;
  margin-left: auto;
  color: #6c7080;
  text-align: center;
  justify-content: flex-end;
  /* Paragraph/P2/Regular */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
}
.account__posts a {
  display: block;
  width: fit-content;
  transition: all 0.3s ease 0s;
}
.account__posts-btn {
  transition: all 0.3s ease 0s;
  display: block;
  width: fit-content;
}
.account__posts-nav {
  transition: all 0.3s ease 0s;
  display: block;
  width: fit-content;
  display: flex;
  gap: 20px;
  align-items: center;
}

.primary {
  color: #2b3144;
}

.bin__orders .tabs__navigation {
  padding: 20px 0;
  display: flex;
  gap: 35px;
  align-items: center;
  color: #848e9c;
  font-family: Arial;
  font-size: 18.745px;
  font-style: normal;
  font-weight: 400;
  line-height: 21.423px; /* 114.286% */
}
.bin__orders-top {
  width: 100%;
  border-bottom: 1.339px solid #252930;
  display: flex;
  align-items: center;
  gap: 60px;
  color: #848e9c;
  font-family: Arial;
  font-size: 16.067px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 12px 0;
}
@media (max-width: 100em) {
  .bin__orders-top {
    justify-content: space-between;
    gap: 30px;
    font-size: 13px;
  }
}
.bin__orders-name {
  width: 120px;
  color: #848e9c;
  font-family: Arial;
  font-size: 14.728px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
@media (max-width: 100em) {
  .bin__orders-name {
    font-size: 14px;
    width: 100px;
  }
}
.bin__orders-name-x {
  color: rgba(254, 119, 1, 1);
  font-family: Arial;
  font-size: 16.067px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: auto;
}
@media (max-width: 100em) {
  .bin__orders-name-x {
    font-size: 14px;
  }
}
.bin__orders-body2 {
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bin__orders-body2 p {
  color: #5e6673;
  text-align: center;
  font-family: Arial;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.mil {
  display: flex;
  align-items: center;
  gap: 21px;
  margin-bottom: 14px;
}

.non {
  display: block !important;
}

.date__title {
  color: rgba(254, 119, 1, 1);
  /* Heading/Web Typography/H5/Medium */
  font-family: Inter;
  font-size: 23px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 121.739% */
}
@media (min-width: 121.875em) {
  .date__title {
    margin-bottom: 1.875rem;
  }
}
@media (min-width: 20em) and (max-width: 121.875em) {
  .date__title {
    margin-bottom: calc(0.625rem + 1.25 * (((100vw - 20rem)) / ((101.875))));
  }
}
@media (max-width: 20em) {
  .date__title {
    margin-bottom: 0.625rem;
  }
}
.date__line {
  padding: 50px 0;
  border-bottom: 1px solid rgba(233, 234, 240, 0.15);
}
.date__l-title {
  margin-bottom: 10px;
  color: #fff;
  /* Paragraph/P2/Regular */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
}
.date__l-title-sub {
  margin-bottom: 30px;
  color: #6c7080;
  /* Paragraph/P1/Regular */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px; /* 121.429% */
}
.date__l-box {
  display: flex;
  gap: 30px;
  width: 100%;
}
.date__inp-wrap {
  width: 100%;
  margin-bottom: 20px;
}
.date__inp-wrap p {
  margin-bottom: 10px;
  display: block;
  color: #fff;
  /* Paragraph/P1/Regular */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px; /* 121.429% */
}
.date__inp-wrap input {
  padding: 14px 20px;
  border-radius: 5px;
  display: block;
  width: 100%;
  background: #080808;
  color: #9295a6;
  /* Paragraph/P1/Regular */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px; /* 121.429% */
}

.vid {
  border-radius: 10px;
  background: var(--dark-theme-section-color, #121318);
}
@media (min-width: 121.875em) {
  .vid {
    padding: 2.5rem;
  }
}
@media (min-width: 20em) and (max-width: 121.875em) {
  .vid {
    padding: calc(1.25rem + 1.25 * (((100vw - 20rem)) / ((101.875))));
  }
}
@media (max-width: 20em) {
  .vid {
    padding: 1.25rem;
  }
}

.date__bottom {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 30px;
  gap: 30px;
}

.date1 {
  padding: 19px 30px;
  border-radius: 10px;
  background: #080808;
  color: rgba(254, 119, 1, 1);
  /* Paragraph/P2/Regular */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
  transition: all 0.3s ease 0s;
}
.date1:hover {
  background: rgba(254, 119, 1, 1);
  color: #222;
}

.date2 {
  padding: 19px 65px;
  border-radius: 10px;
  background: rgba(254, 119, 1, 1);
  color: #222;
  text-align: center;
  /* Paragraph/P2/Regular */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
  transition: all 0.3s ease 0s;
}
.date2:hover {
  background: #080808;
  color: rgba(254, 119, 1, 1);
}

.bin__orders-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 17px 0;
  color: #fff;
  font-family: Arial;
  font-size: 16.067px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
@media (max-width: 100em) {
  .bin__orders-line {
    justify-content: space-between;
    padding: 15px 0;
    gap: 30px;
  }
}
.scrolling-body{
  overflow-y: scroll;
  height: 760px;
}
.bin__orders-line-name {
  width: 120px;
  padding-left: 5px;
  display: flex;
  align-items: center;
  font-family: Arial;
  font-size: 16.067px;
  font-style: normal;
  align-items: center;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  gap: 4px;
}
.bin__orders-line-name p {
  color: #fff !important;
}
.bin__orders-line-name span {
  color: rgba(254, 119, 1, 1);
  font-family: Arial;
  border-radius: 5px;
  background: rgba(252, 212, 53, 0.1960784314);
  font-size: 11px;
  padding: 2px 3px;
  font-style: normal;
  font-weight: 400;
  line-height: 178.522%;
}
@media (max-width: 100em) {
  .bin__orders-line-name span {
    font-size: 10px;
  }
}
@media (max-width: 100em) {
  .bin__orders-line-name {
    font-size: 14px;
    width: 100px;
  }
}
.bin__orders-line-razmer {
  width: 120px;
  color: #0ecb81;
  font-family: Arial;
  font-size: 16.067px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
@media (max-width: 100em) {
  .bin__orders-line-razmer {
    font-size: 14px;
    width: 100px;
  }
}
.bin__orders-line-cena {
  width: 120px;
  color: #fff;
  font-family: Arial;
  font-size: 16.067px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.161px;
}
@media (max-width: 100em) {
  .bin__orders-line-cena {
    font-size: 14px;
    width: 100px;
  }
}
.bin__orders-line-pnl {
  width: 120px;
}

.deposits__body {
  background: #121318;
  padding: 40px;
  border-radius: 10px;
}
.deposits__wrap {
  display: flex;
  gap: 30px;
}
.deposits__bar {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.deposits__q1 {
  cursor: pointer;
  padding: 50px;
  border-radius: 10px;
  background: #080808;
  display: flex;
  justify-content: center;
  gap: 17px;
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
  letter-spacing: 0.16px;
  align-items: center;
}
.deposits__q1:hover {
  background: rgba(254, 119, 1, 1);
}
.deposits__oplata {
  padding: 20px;
  border-radius: 10px;
  background: #080808;
}
.deposits__oplata-line {
  display: flex;
  align-items: center;
  gap: 25px;
  color: #6c7080;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
  letter-spacing: 0.16px;
  margin-bottom: 25px;
}
.deposits__oplata-line .checkbox__input {
  cursor: pointer;
}
.deposits__oplata-line .checkbox__label {
  cursor: pointer;
}
.deposits__summa {
  border-radius: 5px;
  border: 1px solid #6c7080;
  flex: 1 1 auto;
  display: block;
  background: transparent;
  color: #6c7080;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
  letter-spacing: 0.14px;
  padding: 5px;
}
.deposits__reqwi {
  border-radius: 10px;
  background: #080808;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px 35px;
  margin-bottom: 10px;
  margin-top: 10px;
  flex: 1 1 auto;
}
.deposits__reqwi-title {
  margin-bottom: 22px;
  color: #fff;
  font-family: Inter;
  font-size: 23px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 121.739% */
  letter-spacing: 0.23px;
}
.deposits__text {
  margin: 19px 0;
  color: #6c7080;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
  letter-spacing: 0.12px;
}
.deposits__lion {
  border-radius: 5px;
  padding: 15px;
  border-radius: 5px;
  border: 1px solid rgba(204, 206, 217, 0.15);
  width: 100%;
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.32px;
  margin-bottom: 10px;
}
.deposits__lion2 {
  border: 1px solid rgba(204, 206, 217, 0.15);
  padding: 15px;
  border-radius: 5px;
  border: 1px solid rgba(204, 206, 217, 0.15);
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.32px;
  margin-bottom: 10px;
}
.deposits__copy {
  padding: 10px 20px;
  border-radius: 4px;
  background: rgba(254, 119, 1, 1);
  color: #222;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
  letter-spacing: 0.16px;
}
.deposits__copy:hover {
  filter: brightness(0.9);
}
.deposits__btn {
  border-radius: 4px;
  padding: 10px 20px;
  background: rgba(254, 119, 1, 1);
  color: #222;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
  letter-spacing: 0.16px;
  cursor: pointer;
  text-align: center;
}

.deposits__btn:hover {
  filter: brightness(0.9);
}
.deposits__btn:disabled {
  background: rgba(252, 213, 53, 0.8);
  cursor: not-allowed;
}
.check-with-label:checked + .label-for-check {
  color: #fff;
}

.perevod {
  border: 1px solid rgba(204, 206, 217, 0.15);
  padding: 15px;
  border-radius: 5px;
  border: 1px solid rgba(204, 206, 217, 0.15);
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  font-family: Inter;
  background: transparent;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.32px;
  margin-bottom: 10px;
}

.steps {
  margin-bottom: auto;
}

.wallet__box {
  display: flex;
  gap: 30px;
  width: 100%;
}
.wallet__box .button {
  width: 100%;
  padding: 19px;
  border-radius: 10px;
  background: rgba(254, 119, 1, 1);
  margin-top: 50px;
  color: #222;
  text-align: center;
  /* Paragraph/P3/Medium */
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 133.333% */
}
.wallet__item {
  padding: 40px;
  border-radius: 10px;
  background: #121318;
  display: flex;
  width: 100%;
  max-width: 532px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.wallet__item .select {
  color: #fff;
  /* Paragraph/P2/Medium */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  background: transparent;
  border: none;
  outline: none;
  width: 100%;
}
.wallet__item option {
  background: transparent;
  color: #080808;
}
.wallet__title {
  margin-bottom: 40px;
  color: rgba(254, 119, 1, 1);
  font-family: Inter;
  font-size: 23px;
  font-style: normal;
  font-weight: 500;
  line-height: 104.348%;
}
.wallet__block {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
.wallet__line {
  border-radius: 5px;
  border: 1px solid rgba(204, 206, 217, 0.15);
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  padding: 10px;
}

.wallet__item-long {
  padding: 40px;
  margin-top: 30px;
  border-radius: 10px;
  background: #121318;
  display: flex;
  width: 100%;
  max-width: 1094px;
  flex-direction: column;
  justify-content: space-between;
}
.flex-between{
  display: flex;
  justify-content: space-between;
}
.wallet__input_block{
  display: flex;
  justify-content: space-between;
  gap: 40px;
}
.wallet-input{
  width: 100%;
  height: 45px;
  border-radius: 10px;
  padding: 10px;
  color: silver;
  border: 1px solid silver !important;
  background: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.wallet-ri{
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
  background: none;
  text-align: right;
  outline: none;
  font-size: 1rem;
  width: 100%;
}
.wri-left{
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  color: white;
  border: none;
  cursor: pointer;
  width: 100%;
}
.wri-left option {
  background: transparent;
  color: #080808;
}
.wallet-ri__container{
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
  padding: 0 !important;
}
.wi-button{
  width: 100%;
  text-align: center;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  border-radius: 10px;
  cursor: pointer;
}
.apy{
  margin-top: 20px;
  color: white;
  font-size: 1.3rem;
  text-align: center;
  width: 100%;
}
.apyy{
  color: rgba(254, 119, 1, 1);
}
.bg-buy{
  background: #0ecb81;
}
.bg-sell{
  background: #f6465d;
}
.bg-sell:disabled{
  background: rgba(246, 70, 93, 0.83);
  cursor: not-allowed;
}
.wallet__item .select {
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  background: transparent;
  border: none;
  outline: none;
  width: 100%;
}
.wallet__item option {
  background: transparent;
  color: #080808;
}
.wallet__title {
  margin-bottom: 40px;
  color: rgba(254, 119, 1, 1);
  font-family: Inter;
  font-size: 23px;
  font-style: normal;
  font-weight: 500;
  line-height: 104.348%;
}
.wallet__block {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
.wallet__line-left {
  width: 100%;
}
.wallet__right {
  color: #fff;
  display: flex;
  align-items: center;
  /* Paragraph/P1/Regular */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px; /* 121.429% */
}
.wallet__right .select2 {
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px; /* 121.429% */
  background: transparent;
  margin-left: 10px;
  color: #fff;
  width: fit-content;
  outline: none;
}
.wallet__bom {
  margin-top: 40px;
  width: 100%;
}
.wallet__txt {
  margin-bottom: 10px;
  color: rgba(254, 119, 1, 1);
  /* Paragraph/P1/Regular */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px; /* 121.429% */
}
.wallet__bomm {
  border-radius: 5px;
  border: 1px solid rgba(204, 206, 217, 0.15);
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  padding: 10px;
}
.wallet__txt2 {
  color: #848e9c;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.12px;
}
.wallet__value {
  background: transparent;
  color: #fff;
  /* Paragraph/P2/Medium */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}

.bom2 {
  margin-top: 0;
}

.kyc__box {
  padding: 40px;
  border-radius: 10px;
  background: #121318;
  height: 100%;
}
.kyc__tip .select1 {
  width: 100%;
  max-width: 225px;
  margin-bottom: 30px;
  border-radius: 5px;
  background: #080808;
  padding: 15px 20px;
  color: #6c7080;
  /* Paragraph/P2/Regular */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
}
.kyc__inputs {
  display: flex;
  gap: 30px;
  width: 100%;
  border-bottom: 1px solid rgba(233, 234, 240, 0.15);
}
@media (min-width: 121.875em) {
  .kyc__inputs {
    padding-bottom: 3.125rem;
  }
}
@media (min-width: 20em) and (max-width: 121.875em) {
  .kyc__inputs {
    padding-bottom: calc(1.5625rem + 1.5625 * (((100vw - 20rem)) / ((101.875))));
  }
}
@media (max-width: 20em) {
  .kyc__inputs {
    padding-bottom: 1.5625rem;
  }
}
@media (min-width: 121.875em) {
  .kyc__inputs {
    margin-bottom: 3.125rem;
  }
}
@media (min-width: 20em) and (max-width: 121.875em) {
  .kyc__inputs {
    margin-bottom: calc(1.5625rem + 1.5625 * (((100vw - 20rem)) / ((101.875))));
  }
}
@media (max-width: 20em) {
  .kyc__inputs {
    margin-bottom: 1.5625rem;
  }
}
.kyc__item {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 30%;
}
.kyc__item:last-child {
  width: 70%;
}
.kyc__item p {
  color: #6c7080;
  /* Paragraph/P1/Regular */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px; /* 121.429% */
}
.kyc__item input {
  padding: 14px 20px;
  border-radius: 5px;
  background: #080808;
  color: #fff;
  /* Paragraph/P1/Regular */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px; /* 121.429% */
}
.kyc__item input:focus {
  border: 1px solid rgba(254, 119, 1, 1);
}
.kyc__item input:active {
  border: 1px solid rgba(254, 119, 1, 1);
}
.kyc__title2 {
  margin-bottom: 30px;
  color: #fff;
  /* Paragraph/P2/Regular */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
}
.kyc__box2 {
  display: flex;
  gap: 30px;
  padding-bottom: 40px;
}
.kyc__itemm {
  width: 32%;
}
.kyc__b-item {
  height: 100%;
  border-radius: 10px;
  border: 1.5px dashed rgba(204, 206, 217, 0.15);
  padding: 30px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  transition: all 0.3s ease 0s;
}
.kyc__b-item:hover {
  background: #080808;
}
.kyc__b-item input {
  position: absolute;
  width: 100%;
  height: 100%;
  display: block;
  font-size: 0;
  cursor: pointer;
  opacity: 0;
  left: 0;
  top: 0;
  z-index: 12;
}
.kyc__name {
  margin-bottom: 8px;
  color: #fff;
  text-align: center;
  /* Paragraph/P2/Regular */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
}
.kyc__name span {
  color: rgba(254, 119, 1, 1);
}
.kyc__sub {
  color: #9295a6;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.12px;
}

.malina {
  margin-bottom: 20px;
  color: #6c7080;
  /* Paragraph/P1/Regular */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px; /* 121.429% */
}

.btn56 {
  padding: 19px 65px;
  border-radius: 10px;
  background: rgba(254, 119, 1, 1);
  color: #222;
  text-align: center;
  /* Paragraph/P2/Regular */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
  max-width: 215px;
  transition: all 0.3s ease 0s;
  display: block;
  margin-left: auto;
}
.btn56:hover {
  filter: brightness(0.8);
}

.lineeee {
  margin-top: 30px;
  background: rgba(233, 234, 240, 0.15);
  height: 1px;
  margin-bottom: 30px;
  width: 100%;
}

.tpsl {
  display: none;
}

.show {
  display: block !important;
}


.show2 .deposits__q1 {
  background: rgba(254, 119, 1, 1);
}

input[type=radio]:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: transparent;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}

input[type=radio]:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #ffa500;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}

.wallet__line {
  cursor: pointer;
}

.wallet__right .select2 {
  max-width: 50px;
}

@media (max-width: 93.75em) {
  .story-orders__box {
    zoom: 88%;
  }
}
.story-orders__box .tabs__navigation {
  display: flex;
  color: #6c7080;
  /* Paragraph/P2/Regular */
  font-family: Inter;
  gap: 35px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
}
.story-orders__box .tabs__title {
  padding: 0;
  padding-bottom: 30px;
  margin: 0;
  background: transparent !important;
  border: none;
  width: fit-content;
}
.story-orders__box .tabs__title._tab-active {
  border-bottom: 1px solid rgba(254, 119, 1, 1);
  background: transparent;
  border-radius: 0;
}
.story-orders__top-bar {
  padding: 20px 0;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 36px;
  border-top: 1px solid rgba(239, 239, 239, 0.15);
  border-bottom: 1px solid rgba(239, 239, 239, 0.15);
}
.story-orders__top-item {
  display: flex;
  align-items: center;
  color: #6c7080;
  /* Paragraph/P1/Regular */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px; /* 121.429% */
}
.story-orders__top-item:first-child {
  width: 140px;
}
.story-orders__top-item:nth-child(5) {
  width: 140px;
}


.story-orders__top-item:nth-child(6) {
  padding-left: 0;
}
.story-orders__top-item:nth-child(3) {
  padding-left: 20px;
}
.story-orders__top-item:nth-child(2) {
  padding-left: 20px;
}
.story-orders__top-item:nth-child(5) {
  padding-left: 10px;
}
.story-orders__top-item:nth-child(7) {
  padding-left: 15px;
}
.story-orders__line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}
.story-orders__line .bin__orders-line-name {
  padding-left: 0;
  border-left: none;
  width: 140px;
}
.story-orders__line-storona {
  display: flex;
  gap: 25px;
  width: 125px;
  align-items: center;
}
.story-orders__storona-value {
  color: #fff;
  /* Paragraph/P2/Regular */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 137.5%;
}
.bin__c-line {
  margin-bottom: 0 !important;
  padding-top: 7px;
  padding-bottom: 7px;

  transition: 1s;
}
.bin__b-body .prevod:hover{
  color: #be9514;
}
.bin__right .bin__c-line{
  margin-bottom: 0 !important;
  padding: 2px 0 !important;
  border-radius: 5px;
}
.bin__right .bin__c-line:hover{
  padding: 2px 5px !important;
}
.bin__c-line:hover {
  cursor: pointer;
  padding: 7px 5px !important;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.17);
}
@media (max-width: 100em) {
  .story-orders__storona-value {
    font-size: 14px;
  }
}
.story-orders__storona-data {
  color: #6c7080;
  /* Footer/Regular */
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px; /* 140% */
}
.story-orders__line-item {
  width: 123px;
  color: #fff;
  /* Paragraph/P2/Regular */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 137.5%;
}
.story-orders__line-item:nth-child(5) {
  width: 140px;
}

.account__sidebar {
  min-width: 275px;
}
@media (max-width: 100em) {
  .account__sidebar {
    zoom: 80%;
  }
}
.checkbox__text{
  width: 165px;
}
.deposits__summa {
  opacity: 1;
  max-width: 50%;
  flex: 1 1 auto;
}

@media (max-width: 100em) {
  .account__cont {
    zoom: 85%;
  }
}
@media (max-width: 81.25em) {
  .account__cont {
    zoom: 70%;
  }
}

@media (max-width: 100em) {
  .deposits__oplata-line {
    font-size: 14px;
  }
}

.show3 {
  opacity: 1 !important;
}

.account__balans-value {
  cursor: pointer;
}

#faPlus {
  display: none;
}
.swiper {
  overflow: hidden;
}

.swiper-wrapper {
  width: 100%;
  height: 100%;
  box-sizing: content-box;
  display: flex;
  position: relative;
}

.swiper-vertical .swiper-wrapper {
  flex-direction: column;
}

.swiper-autoheight .swiper-wrapper {
  align-items: flex-start;
}

.swiper-initialized .swiper-slide {
  flex-shrink: 0;
}

.swiper-android .swiper-slide,
.swiper-android .swiper-wrapper {
  transform: translate3d(0px, 0, 0);
}

.swiper-button-lock {
  display: none !important;
}

.portfolio__attention{
    width: 100%;
    background: #080808;
    border-radius: 16px;
    padding: 40px 0;
}
.portfolio__attention2{
    width: 100%;
    background: #080808;
    border-radius: 16px;
    padding: 40px 0;
    display: flex;
    justify-content: center;
}

.portfolio__attention-title{
    color: #FF1515;
    font-size: 1.5rem;
    text-align: center;
    font-weight: 700;
    padding-bottom: 30px;

}

.portfolio__active-title{
    font-size: 1.5rem;
    color: rgba(254, 119, 1, 1);
    text-align: center;
    font-weight: 700;
    margin-bottom: 0;
}

.portfolio__active-title2{
    font-size: 1.5rem;
    margin-left: 5px;
    color: rgba(254, 119, 1, 1);
    text-align: center;
    font-weight: 700;
    margin-bottom: 0;
}
.portfolio__desc-item{
    padding: 40px;
    display: flex;
    justify-content: space-between;
    border-radius: 16px;
    width: 100%;
    margin-bottom: 20px;
}
.portfolio__desc-sum{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.portfolio__desc-description{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: .75rem;
    width: 35%;
    text-align: center;
}
.portfolio__desc-img{
    height: 100%;
}
.portfolio__attention-text{
    color: rgba(254, 119, 1, 1);
    font-size: 1.2rem;
    text-align: center;
    font-weight: 700;
}
.portfolio__continer{
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 20px;
}
.portfolio__blur-item{
    border-radius: 13px;
    width: 31%;
}
.activate_reserved{
    width: 20%;
    margin-left: 40%;
    padding: 10px 0;
    border-radius: 8px;
    border: 2px solid rgba(254, 119, 1, 1);
    transition: 0.5s;
    font-size: .9rem;
}
.activate_reserved:hover{
    background: rgba(254, 119, 1, 1);
    color: black;
}
.portfolio__item{
    cursor: pointer;
    border-radius: 13px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 10px;
}
.subcat{
    background: rgba(254, 119, 1, 1);
    color: black;
    padding: 0 5px;
    margin-left: 5px;
    border-radius: 3px;
}
.portfolio__item:not(:last-child){
    margin-right: 3%;
}
.portfolio__item3{
    border-radius: 16px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 10px;
}


.portfolio__button{
    width: 48%;
    padding: 9px;
    border-radius: 20px;
    border: 3px solid rgba(254, 119, 1, 1);
    color: rgba(254, 119, 1, 1);
    font-weight: 700;
    font-size: 0.8rem;
    transition: 0.5s;
}
.portfolio__button:hover{
    color: black;
    background: rgba(254, 119, 1, 1);
}

.portfolio__button2{
    width: 100%;
    padding: 9px;
    border-radius: 20px;
    border: 3px solid rgba(254, 119, 1, 1);
    color: rgba(254, 119, 1, 1);
    font-weight: 700;
    font-size: 0.8rem;
    transition: 0.5s;
}
.portfolio__button2:hover{
    color: black;
    background: rgba(254, 119, 1, 1);
}
.portfolio__item:hover{
    background: rgba(254, 119, 1, 1);
}
.portfolio__item:hover .portfolio__item-tc{
    color: black;
}
.portfolio__item:hover .portfolio__type{
    color: black !important;
}
.portfolio__item:hover .portfolio__desc-title{
    color: black !important;
}
.portfolio__item:hover .portfolio__desc-text{
    color: black !important;
}
.portfolio__item2{
    cursor: pointer;
    border-radius: 13px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 10px;
}

.dflp:not(:last-child){
    margin-right: 3%;
}
.dflp{
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.bolvanka{
    width: 100%;
}
.portfolio__item2:hover{
    background: rgba(254, 119, 1, 1);
}
.portfolio__item2:hover .portfolio__item-tc{
    color: black;
}
.portfolio__item2:hover .portfolio__type{
    color: black !important;
}
.portfolio__item2:hover .portfolio__desc-title{
    color: black !important;
}
.portfolio__item2:hover .portfolio__desc-text{
    color: black !important;
}
.portfolio__item2:hover .portfolio__desc-price-title{
    color: black !important;
}
.portfolio__item2:hover .portfolio__desc-price{
    color: black !important;
}
.bronze{
    border: 3px solid #E86014;
}
.silver{
    border: 3px solid white;
}
.gold{
    border: 3px solid rgba(254, 119, 1, 1);
}
.bronzet{
    color: #E86014;
}
.silvert{
    color: white;
}
.goldt{
    color: rgba(254, 119, 1, 1);
}
.portfolio__case{
    height: 100%;
}
.portfolio__item-tc{
    transition: 0.5s;
    width: 60%;
    padding-right: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
}
.portfolio__type{

    transition: 0.5s;
    margin-bottom: 0;
    white-space: nowrap;
    font-weight: 700;
    font-size: .80rem;
}
.portfolio__price{
    white-space: nowrap;
    font-weight: 700;
    margin-bottom: 0;
    font-size: 2.5rem;
}
.portfolio__price2{
    white-space: nowrap;
    font-weight: 700;
    margin-bottom: 0;
    font-size: 2rem;
}
.portfolio__desc-title{
    text-align: center;
    width: 100%;
    font-weight: 700;
}
.portfolio__desc-text{
    text-align: center;
    font-size: .75rem;
    width: 100%;
}
.portfolio__desc-price-title{
    margin-top: 10px;
    text-align: center;
    font-weight: 700;
    font-size: 1.1rem;
    margin-bottom: 0;
}
.portfolio__desc-price{
    text-align: center;
    font-weight: 700;
    font-size: 2rem;
    margin-bottom: 0;
}



.portfolio__item4{
    cursor: pointer;
    width: 100%;
    padding: 30px 2%;
    border-radius: 13px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: 0.5s;
}
.portfolio__item4:not(:last-child){
    margin-right: 3%;
}


.portfolio__item4:hover{
    background: rgba(254, 119, 1, 1);
}
.portfolio__item4:hover .portfolio__item-tc{
    color: black;
}
.portfolio__item4:hover .portfolio__type {
    color: black !important;
}
.account__item:hover{
    background: rgba(254, 119, 1, 1);
    cursor: pointer;
    color: black !important;
}
.account__item{
transition: 0.4s;
}
.account__item {
    width: 49%;
    padding: 23px;
    border-radius: 10px;
    background: #121318;
}
.deposits__q1{
transition: 0.4s;
}
.active__ai{
    background: rgba(254, 119, 1, 1) !important;
    color: black !important;
}
.account__i-top-black{
    color: black;
}
.account__s-name:hover{
    color: rgba(254, 119, 1, 1);
}
.popper__button{
    color: #b9b9b9;
    margin-right: 10px;
}
.popper__box{
    padding: 10px;
    border: 1px solid #3b3b3b;
    background: #181818;
    margin-top: 10px;
    margin-right: 10px;
    color: #c9c9c9;
    border-radius: 7px;
}
.account__flex3 {
    display: flex;
    width: 100%;
    gap: 40px;
    padding: 37px 30px;
    padding-top: 0;
}

.balance__list{
    width: 100%;
    padding: 20px 0;
    background: #121318;
    border-radius: 10px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

.grid-item {
    padding: 10px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    font-size: 18px;
}
.grid-flex{
    display: flex;
    align-items: center;
}
.grid-img{
    height: 30px;
    margin-right: 15px;
}
.grid-children{
    margin-bottom: 0;
}
.grid-item:nth-child(1),
.grid-item:nth-child(2),
.grid-item:nth-child(4),
.grid-item:nth-child(5),
.grid-item:nth-child(7),
.grid-item:nth-child(8),
.grid-item:nth-child(10),
.grid-item:nth-child(11),
.grid-item:nth-child(13),
.grid-item:nth-child(14),
.grid-item:nth-child(16),
.grid-item:nth-child(17) {
    border-right: 1px solid rgba(254, 119, 1, 1);
}

.l-body{
    /*background: no-repeat url('../images/mainbg.png');*/
    background: rgba(20, 20, 20, 1);
    background-size: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: -81px;
}
.start-btn{
    z-index: 2;
    transition: 0.5s;
    background: rgba(254, 119, 1, 1);
    color: white;
    width: 260px;
    padding: 10px;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 1rem;
}
.start-btn:hover{
    border: solid 2px rgba(254, 119, 1, 1);
    background: rgba(38, 38, 38, 0.5);
    color: rgba(254, 119, 1, 1);
}
.start-btn2{
    z-index: 2;
    transition: 0.5s;
    background: rgba(254, 119, 1, 1);
    color: white;
    width: 160px;
    padding: 10px;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 1rem;
}
.start-btn2:hover{
    border: solid 2px rgba(254, 119, 1, 1);
    background: rgba(38, 38, 38, 0.5);
    color: rgba(254, 119, 1, 1);
}
.l-title{
    font-size: 1.5rem;
    color: white;
    font-weight: 600;
    text-align: left;
}
.land-start{
    display: flex;
    width: 100%;
}
.l-block1{
    width: 100%;
    background: rgba(13, 13, 13, 1);
    margin-top: 80px;
    display: flex;
    justify-content: center;
}
.l-block1-1{
    display: flex;
    justify-content: center;
    padding: 86px 0;
    flex-direction: column;
}
.l-block-1-1-1{
    display: flex;
    justify-content: space-between;
    width: 75%;
    max-width: 1200px;
}
.phonem{
    padding-top: 86px;
    padding-right: 86px;
    height: 370px;
}
.land_sinput{
    background: rgba(27, 27, 27, 1);
    border: 1px solid rgba(72, 72, 72, 1);
    padding: 10px;
    width: 50%;
    border-radius: 6px;
    margin-right: 30px;
}



.l-block2{
    width: 75%;
    max-width: 1200px;
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(3, 1fr);
    gap: 40px 50px;
    align-items: center;
    margin-bottom: 15rem;
}
.l-block2-text-container{
    width: 90%;
}
.l-block2__image{
    height: 200px;
    transition: 0.5s;
}
.l-block2__item{
    padding-top: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 380px;
    justify-content: center;
    border-radius: 8px;
    border: 1px solid rgba(41, 41, 41, 1);
    background: rgba(27, 27, 27, 1);
    transition: 0.5s;
}
.l-block2__item:hover{
    border: solid 2px rgba(254, 119, 1, 1);
}
.l-block2__item:hover .l-block2__image{
    scale: 1.03;
}
.l-block2__title{
    font-size: 1.1rem;
    font-weight: 800;
    color: white;
    padding: 32px 0 11px 0;
}
.l-block2__subtitle{
    font-size: 0.9rem;
    color: rgba(122, 126, 132, 1);
    line-height: 120%;
}
.l-block2__lg{
     margin-top: 16px;
     margin-bottom: 32px;
     font-size: 1.1rem;
     font-weight: 600;
     color: rgba(254, 119, 1, 1);
 }


.l-block3__subtitle{
    font-size: 1rem;
    margin-bottom: 0;
    color: #898CA9;
    line-height: 150%;
}
.l-block3{
    width: 75%;
    max-width: 1200px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 140px;
}
.l-block3__title{
    margin-bottom: 0;
    font-size: 2.4rem;
    width: 75%;
    max-width: 1200px;
    text-align: left;
    font-weight: 800;
    color: white;
}
.l-block3__subtitle{
    margin-bottom: 0;
    font-size: 1rem;
    width: 75%;
    max-width: 1200px;
    text-align: left;
    color: rgba(122, 126, 132, 1);
}
.main-color{
    color: rgba(254, 119, 1, 1);
}
.mb16{
    margin-bottom: 16px;
}



.l-block4{
    display: flex;
    margin-top: 100px;
    margin-bottom: 300px;
    width: 75%;
    max-width: 1200px;
    justify-content: space-around;
}
.shield-img{
    width: 400px;
}
.l-block4__item{
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.aic{
    align-items: center;
}
.l-block4__title{
    font-size: 2.4rem;
    line-height: 0.7;
    font-weight: 800;
    color: white;
}
.l-block__subtitle{
    font-size: 0.8rem;
    color: #898CA9;
    line-height: 70%;
}
.mb32{
    margin-bottom: 2.4rem;
}
.mtsub{
    margin-top: 2.5rem;
}
.l-block5-form{
    display: flex;
    justify-content: center;
}


.l-block6{
    width: 100%;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 100px;
}
.l-block-vid{
    width: 75%;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 100px;
}
.l-graph__container{
    width: 75%;
    max-width: 1200px;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem 0;
    /*background: #1A1B23;*/
    border-radius: 25px;
}
.l-graph__item{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 64px;
    width: 100%;

}
.l-graph__coin{
    font-weight: 700;
    color: white;
    width: 15%;
    text-align: left;
    font-size: 20px;
}
.l-graph__title{
    font-weight: 700;
    color: white;
    font-size: 20px;
    width: 20%;
    text-align: left;
}
.l-graph__percent{
    font-weight: 700;
    width: 10%;
    text-align: left;
    font-size: 20px;
}
.text-error{
    color: #f6465d;
}
.l-graph__link{
    font-weight: 700;
    color: white;
    font-size: 20px;
    transition: 0.5s;
}
.l-graph__link:hover{
    color: rgba(254, 110, 1, 1);
}
.l-graph-arrow{
    transition: 0.5s;
}
.l-graph__link:hover .l-graph-arrow{
    stroke: rgba(254, 110, 1, 1);
}
.l-graph-arrow2{
    transition: 5s;
}
.l-graph__link:hover .l-graph-arrow2{
    stroke: rgba(254, 110, 1, 1);
}
.l-graph__abr{
    width: 10%;
    text-align: left;
    font-weight: 700;
    color: rgba(254, 119, 1, 1);
    font-size: 20px;
}
.l-graph__item:not(:last-child){
border-bottom: 2px solid #2B2C3B;
}
.l-block5{
    display: flex;
    flex-direction: column;
    width: 75%;
    max-width: 1200px;
    padding: 8rem 0;
    gap: 2rem;
    text-align: center;
    justify-content: space-around;
}
.l-block-stat{
    display: flex;
    justify-content: center;
    padding: 6rem 0;
    background: rgba(13, 13, 13, 1);
    width: 100%;
}
.l-block-stat-2{
    display: flex;
    justify-content: space-between;
    width: 75%;
    max-width: 1200px;
}
.l-block-stat-item{
    display: flex;
    width: 25%;
    flex-direction: column;
    align-items: center;
}
.l-block-stat-title{
    font-size: 2.4rem;
    font-weight: 600;
    color: rgba(254, 119, 1, 1);
    text-align: center;
}
.l-block-stat-description{
    text-align: center;
    width: 90%;
}
.l-block-select{
    width: 75%;
    max-width: 1200px;
    padding: 50px 0;
    margin-bottom: 20px;
}
.l-select-item-selected{
    transition: 1s;
    color: white;
    border-bottom: 3px solid rgba(254, 119, 1, 1) !important;
}
.l-select-item{
    transition: 1s;
    border-bottom: none;
    cursor: pointer;
    padding-bottom: 10px;
    margin-bottom: -2px;
    font-weight: 700;
    font-size: 1.2rem;
}
.l-select-item:hover{
    color: white;
}
.l-select-bar{
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid rgba(41, 41, 41, 1);
}
.d-flex{
    display: flex;
    gap: 0 20px;

}
.sel-icon{
    height: 50px;
    margin-top: 32px;
}
.l-block4-text-container{
    width: 40%;
}
.l-block-select-item{
    display: flex;
    justify-content: space-between;
}
.img-w{
    width: 300px;
    margin-top: 32px;
}
.l-grid-smi{
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 25px;
    padding: 50px 0;
    width: 75%;
    max-width: 1200px;
}
.l-item-smi{
    height: 72px;
    background: rgba(27, 27, 27, 1);
    border: 1px solid rgba(41, 41, 41, 1);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.5s;
    border-radius: 3px;
}
.l-item-smi:hover{
    border: 1px solid rgb(70, 70, 70);
}
.img-smi{
    height: 70px;
}
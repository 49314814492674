
.changepass-popup-window{
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(28, 28, 28, 0.49);
}
.center-do{
    display: flex;
    padding-top: 200px;
    padding-bottom: 200px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.changepass-title{
    font-size: 1.3 rem;
    margin-bottom: 50px;
}
.changepass-popup{
    display: flex;
    flex-direction: column;
    padding: 20px;
    align-items: center;
    width: 28%;
    border-radius: 15px;
    background: #121318;
}
.changepass-button{
    padding: 10px 0px;
    border-radius: 10px;
    width: 80%;
    background: rgba(254, 119, 1, 1);
    color: #222;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    transition: all .3s ease 0s;
}
.changepass-button:hover{
    background: rgba(252, 213, 53, 0.65);
}
.changepass-popup input{
    padding:14px 20px;
    border-radius:5px;
    display:block;
    width:100%;
    background:#080808;
    color:#9295a6;
    font-family:Inter;
    font-size:14px;
    font-style:normal;
    font-weight:400;
    line-height:17px
}
.date__inp-wrap2{
    width:80%;
    margin-bottom:20px
}
.changepass-popup p{
    margin-bottom:10px;
    display:block;
    color:#fff;
    font-family:Inter;
    font-size:14px;
    font-style:normal;
    font-weight:400;
    line-height:17px
}
.dp-pp-text2{
    font-weight: 600;
    font-size: 1.2rem;
}
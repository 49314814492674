.order-table__item{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 17px 0;
    color: #fff;
    font-size: 16px;
}

.story-orders__line-storona{
    gap: 10px !important;
}
.story-orders__top-container{
    display: flex;
    justify-content: space-between;
}
.tpsledit{
    display: flex;
    align-items: center;
}
.tpsledit2{
    display: flex;
    justify-content: start;
    align-items: center;
}
.editIcon{
    width: 17px !important;
    margin-bottom: 4px;
    margin-left: 10px;
    cursor: pointer;
}

.doneIcon{
    width: 17px !important;
    margin-left: 10px;
    cursor: pointer;
}
.doneIcon:hover{
     fill: rgba(254, 119, 1, 1) !important;
 }
.editIcon:hover{
    fill: rgba(254, 119, 1, 1) !important;
}
.ti2{
    padding-left: 60px;
    margin-left: 20px;
    margin-right: 60px;
}
.ti3{
    padding-left: 20px;
}
.dkjfg{
    text-align: left;
    width: 12% !important;
}
.order-table__children{
    width: 11%;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.order-table__children__input{
    width: 50px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    background: none;
    line-height: normal;
}
.bin__orders-line-cena_r{
    font-size: 13px !important;
}
.bin_razmer-usualy{
    width: 10% !important;
}
.order-table__children-header{
    width: 11%;
    color: #848e9c;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.bin__orders-line-name{
    width: 8% !important
}

.bin__orders-line-name-cr {
    width: 11% !important;
}

.long-ord{
    border-left: 2.5px solid #0ecb81 !important;
}
.short-ord{
    border-left: 2.5px solid #de3b00 !important;
}
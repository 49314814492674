@charset "UTF-8";
@import url(https://fonts.googleapis.com/css?family=Inter:100,200,300,regular,500,600,700,800,900);
*{
    padding:0;
    margin:0;
    border:0
}
.ptfnew {
    border-radius: 5px;
    background: rgba(254, 119, 1, 1) ;
    color: black;
    margin-left: -10px;
    font-weight: 600;
    padding: 0 5px;
    font-size: .8rem;
}
.account .tabs__title:hover .svg-portfolio path {
    stroke: rgba(254, 119, 1, 1) !important;
}
.account ._tab-active .svg-portfolio path {
    stroke: rgba(254, 119, 1, 1) !important;
}
.dangob{
    border: 1px solid red;
    border-top: none;
}
.dangobt{
    border: 1px solid red;
    border-bottom: none;
}
.h100vh{
    min-height: calc(100vh - 82px);
}
.svg-portfolio{
    fill: none !important;
}
.account .tabs__title:hover svg-portfolio {
    fill: none !important;
}
*,:after,:before{
    -webkit-box-sizing:border-box;
    box-sizing:border-box
}
body,html{
    height:100%;
    min-width:320px
}
.account__s-photo{
    width: 150px;
    height: 150px;
    border-radius: 50%;

}
.centeryo{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.account__s-item{
    display: flex;
    width: 100%;
    justify-content: center;

}
.account__s-change{
    border-radius: 50%;
    cursor: pointer;
    width: 100%;
    color: rgb(0, 0, 0, 0);
    height: 100%;
}
.kdjsf{
    text-align: center;
}
.account__s-uploadbtn{
    background: rgba(254, 119, 1, 1);
    padding: 10px;
    color: black;
    border-radius: 10px;
    margin-top: 10px;
}
.kyc__b-itemdjksfn {
    height: 100px;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 10px;
    border: 1.5px dashed rgba(204, 206, 217, 0.15);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
    transition: all 0.3s ease 0s;
}
.kyc__b-itemdjksfn:hover {
    background: #080808;
}
.kyc__b-itemdjksfn input {
    position: absolute;
    width: 100%;
    height: 100%;
    display: block;
    font-size: 0;
    cursor: pointer;
    opacity: 0;
    left: 0;
    top: 0;
    z-index: 12;
}
.deposit-popup-window{
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(28, 28, 28, 0.49);
}
.dep-popup1234{
    height: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 20px;
    z-index: 4;
    align-items: end;
    padding-right: 20px;
    width: 28%;

    border-radius: 15px 15px 0 0;
    background: #080808;
}
.deposit-popup123{
    padding: 70px 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 28%;
    border-radius: 0 0 15px 15px;
    background: #080808;
}
.dp-pp-text{
    font-weight: bold;
    font-size: 1.5rem;
}
.MuiCircularProgress-svg{
    width: 60px;
    height: 60px;
}
.account__s-change:hover{
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    width: 100%;
    color: white;
    height: 100%;
    background: rgb(0, 0, 0, 0.5);
}
body{
    color:#848e9c;
    line-height:1;
    font-family:Arial;
    font-size:.875rem;
    -ms-text-size-adjust:100%;
    -moz-text-size-adjust:100%;
    -webkit-text-size-adjust:100%;
    -webkit-font-smoothing:antialiased;
    -moz-osx-font-smoothing:grayscale
}
.mb0{
    margin-bottom: 0;
}
.bin__swiper{
    flex-direction: row;
    display: flex;
    justify-content: space-between;
}
.ipg{
    background: none !important;
}
.bin__search {
    width: 100%;
    margin-bottom: 16px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border-radius: 5.388px;
    background: #2b3139;
    padding: 3px;
    gap: 5px;
}
button,input,textarea{
    font-family:Arial;
    font-size:inherit
}
button{
    cursor:pointer;
    color:inherit;
    background-color:inherit
}
a{
    color:inherit
}
a:link,a:visited{
    text-decoration:none
}
a:hover{
    text-decoration:none
}
ul li{
    list-style:none
}
img{
    vertical-align:top
}
h1,h2,h3,h4,h5,h6{
    font-weight:inherit;
    font-size:inherit
}
body{
    background:color(srgb 0.0784 0.0784 0.0784)
}
.lock body{
    overflow:hidden;
    -ms-touch-action:none;
    touch-action:none
}
.wrapper{
    min-height:100%;
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-orient:vertical;
    -webkit-box-direction:normal;
    -ms-flex-direction:column;
    flex-direction:column;
    overflow:hidden
}
.wrapper>main{
    -webkit-box-flex:1;
    -ms-flex:1 1 auto;
    flex:1 1 auto
}
.wrapper>*{
    min-width:0
}
[class*=__container]{
    max-width:120rem;
    -webkit-box-sizing:content-box;
    box-sizing:content-box;
    margin:0 auto;
    padding:0 .9375rem
}
[class*=-ibg]{
    position:relative
}
[class*=-ibg] img{
    position:absolute;
    width:100%;
    height:100%;
    top:0;
    left:0;
    -o-object-fit:cover;
    object-fit:cover
}
[class*=-ibg_contain] img{
    -o-object-fit:contain;
    object-fit:contain
}
.header{
    padding:21px 0
}
@media (min-width: 2200px) {
    .header__container{
        width: 75%;
        max-width: 1200px !important;
    }
    footer{
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .footer-container{
        width: 75%;
        max-width: 1200px !important;
    }
}
.header__top{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-pack:justify;
    -ms-flex-pack:justify;
    justify-content:space-between;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center
}
.header__top-right{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex
}
.header__money{
    margin-right:45px;
    padding:5px 15px;
    max-width:152px;
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-pack:center;
    -ms-flex-pack:center;
    justify-content:center;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center;
    gap:8.5px;
    color:#181a20;
    text-align:center;
    font-family:Arial;
    font-size:15px;
    font-style:normal;
    font-weight:700;
    line-height:178.522%;
    border-radius:5.356px;
    background:rgba(254, 119, 1, 1);
    width:100%
}
.header__money:hover{
    background: rgba(254, 119, 1, 0.7);
    color: black;
}
.header__btn{
    margin-right:18px;
    position:relative;
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center;
    cursor:pointer
}
.header__btn ul{
    display:none;
    -webkit-box-shadow:rgba(24,26,32,.1) 0 0 1px,rgba(71,77,87,.08) 0 7px 14px,rgba(24,26,32,.08) 0 3px 6px;
    box-shadow:rgba(24,26,32,.1) 0 0 1px,rgba(71,77,87,.08) 0 7px 14px,rgba(24,26,32,.08) 0 3px 6px;
    border-radius:8px;
    background-color:#1e2329;
    padding:16px;
    -webkit-box-sizing:border-box;
    box-sizing:border-box;
    position:absolute;
    left:0;
    top:100%;
    z-index:100;
    width:200px
}
.header__btn li{
    padding:10px;
    margin-bottom:10px
}
.header__btn li:hover{
    background:rgba(24,26,32,.6392156863);
    border-radius:5px
}
.header__btn:hover ul{
    display:block
}
.header__btn-hover{
    z-index: 2;
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    color:#eaecef;
    font-family:Arial;
    font-size:18.745px;
    font-style:normal;
    font-weight:400;
    line-height:normal;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center
}
.already{
    width: 100%;
    padding-top: 200px;
    padding-bottom: 200px;
    font-size: 1rem;
    color: #00a100;
    text-align: center;
    vertical-align: middle;
}
.header__btn-hover span{
    z-index: 2;
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    transition: 0.3s;
}
.header__btn-hover span:hover{
    color: rgba(254, 119, 1, 1);
}
.header__indi-box{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    gap:30px;
    margin:0 10px;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center
}
.header__indi{
    position:relative;
    -webkit-transition:all .3s ease 0s;
    transition:all .3s ease 0s
}
.header__indi:hover{
    -webkit-transform:scale(1.1);
    -ms-transform:scale(1.1);
    transform:scale(1.1)
}
.header .count{
    position:absolute;
    right:-3px;
    top:-3px;
    border-radius:133889.875px;
    background:#f6465d;
    min-width:18px;
    min-height:18px;
    padding:3px;
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center;
    -webkit-box-pack:center;
    -ms-flex-pack:center;
    justify-content:center;
    text-align:center;
    color:#fff;
    display:block
}
.bin__left{
    width:100%;
    max-width:1450px
}
.bin__top{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center;
    padding:13.5px 0
}
.bin__para{
    padding-right:30px;
    position:relative;
    border-right:1.339px solid #252930
}
.bin__para span{
    color:#eaecef;
    font-family:Arial;
    font-size:25.439px;
    font-style:normal;
    font-weight:400;
    line-height:normal;
    display:block;
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    gap:3px;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center;
    -webkit-box-pack:center;
    -ms-flex-pack:center;
    justify-content:center
}
.bin__para span img{
    -webkit-filter:brightness(0) invert(1);
    filter:brightness(0) invert(1);
    margin-bottom:5px
}
.bin__para:hover .bin__pars{
    display:block;
    z-index:100
}
.bin__body{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-pack:center;
    -ms-flex-pack:center;
    justify-content:center
}
.bin__pars{
    -webkit-box-shadow:rgba(24,26,32,.1) 0 0 1px,rgba(71,77,87,.08) 0 7px 14px,rgba(24,26,32,.08) 0 3px 6px;
    box-shadow:rgba(24,26,32,.1) 0 0 1px,rgba(71,77,87,.08) 0 7px 14px,rgba(24,26,32,.08) 0 3px 6px;
    border-radius:8px;
    background-color:#1e2329;
    padding:16px;
    -webkit-box-sizing:border-box;
    box-sizing:border-box;
    position:absolute;
    left:0;
    top:105%;
    width:200px;
    display:none
}
.bin__pars li{
    padding:10px;
    margin-bottom:10px
}
.bin__pars li:hover{
    background:rgba(24,26,32,.6392156863);
    border-radius:5px
}
.bin__two{
    margin-right:40px;
    margin-left:30px
}
.bin__t-top{
    margin-bottom:4px;
    color:#f6465d;
    font-family:Arial;
    font-size:20.084px;
    font-style:normal;
    font-weight:400;
    line-height:normal
}
.bin__t-bottom{
    color:#eaecef;
    font-family:Arial;
    font-size:14.728px;
    font-style:normal;
    font-weight:400;
    line-height:normal
}
.bin__t-item{
    margin-right:20px
}
.bin__t-verh{
    margin-bottom:5px;
    color:#848e9c;
    font-family:Arial;
    font-size:14.728px;
    font-style:normal;
    font-weight:400;
    line-height:145.455%
}
.bin__t-niz{
    color:#eaecef;
    font-family:Arial;
    font-size:14.728px;
    font-style:normal;
    font-weight:400;
    line-height:normal
}
.bin__right{
    width:100%;
    max-width:424px
}
.bin__wrap{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    width:100%
}
.bin__w-left{
    max-width:424px;
    padding:11px 21px;
    width:100%;
    padding-left:0
}
.bin__l-top{
    margin-bottom:16px
}
.bin__search{
    width:100%;
    margin-bottom:16px;
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    border-radius:5.388px;
    background:#2b3139;
    padding:3px;
    gap:5px
}
.bin__search .input{
    -webkit-box-flex:1;
    -ms-flex:1 1 auto;
    flex:1 1 auto;
    background:0 0;
    outline:0;
    color:#eaecef!important;
    display:block
}
.bin__slider-box{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    gap:11px;
    width:100%;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center
}
.bin__line{
    cursor:pointer
}
.bin__slider{
    width:100%;
    margin:0 auto
}
.bin__slide{
    color:#848e9c;
    font-family:Arial;
    font-size:13.3px !important;
    font-style:normal;
    font-weight:600;
    line-height:normal;
    text-align:center;
    text-transform:uppercase
}
.bin__slide a:hover{
    color:rgba(254, 119, 1, 1)
}
.bin__w-chart{
    width:100%;
    max-width:1055px
}
.oranj{
    color:rgba(254, 119, 1, 1)
}
.bin__c-top{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    margin-bottom:20px;
    -webkit-box-pack:justify;
    -ms-flex-pack:justify;
    justify-content:space-between
}
.bin__c-top-item{
    width:30%;
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center;
    -webkit-box-pack:end;
    -ms-flex-pack:end;
    justify-content:flex-end
}
.bin__c-top-item:first-child{
    -webkit-box-pack:start!important;
    -ms-flex-pack:start!important;
    justify-content:flex-start!important
}
.bin__c-body{
    width:100%
}
.bin__c-line{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    display:flex;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center;
    -webkit-box-pack:justify;
    -ms-flex-pack:justify;
    justify-content:space-between
}
.bin__c-line:not(:last-child){
    margin-bottom:15px
}
.bin__c-name{
    width:30%;
    color:#eaecef;
    font-family:Arial;
    font-size:14.817px;
    font-style:normal;
    font-weight:600;
    line-height:normal
}
.bin__c-name i{
    color:#848e9c;
    font-style:normal
}
.bin__c-price{
    width:30%;
    text-align:right;
    color:#b7bdc6;
    font-family:Arial;
    font-size:14.817px;
    font-style:normal;
    font-weight:400;
    line-height:normal
}
.bin__c-percent{
    width:30%;
    text-align:right;
    font-family:Arial;
    font-size:14.817px;
    font-style:normal;
    font-weight:400;
    line-height:normal
}
.red{
    color:#f6465d
}
.green{
    color:#0ecb81
}
.bin__w-grafik{
    height:510px;
    min-width:720px
}
.bin__b-para{
    padding:20px 30px;
    background:#1e2026;
    color:#eaecef;
    font-family:Arial;
    font-size:18.745px;
    width:-webkit-fit-content;
    width:-moz-fit-content;
    width:fit-content;
    font-style:normal;
    font-weight:400;
    line-height:normal;
    border-top: 3px solid rgb(77, 77, 77);
}
.bin__b-body{
    background:#1e2026;
    padding:21px;
    padding-top:0
}
.bin__b-body .select select{
    outline:0;
    padding:3px 2px;
    border-radius:5px;
    background:rgba(217,217,217,.5);
    -webkit-backdrop-filter:blur(8.5px);
    backdrop-filter:blur(8.5px);
    color:#222;
    text-align:center;
    font-family:Arial;
    font-size:18.745px;
    font-style:normal;
    font-weight:400;
    line-height:normal
}
.bin__b-body .znak{
    margin-right:auto;
    cursor:pointer
}
.bin__b-body .prevod{
    color:rgba(254, 119, 1, 1);
    font-family:Arial;
    font-size:19px;
    font-style:normal;
    font-weight:400;
    line-height:normal
}
.bin__b-body .tabs__navigation{
    color:#848e9c;
    font-family:Arial;
    font-size:18.745px;
    font-style:normal;
    font-weight:400;
    line-height:16.067px;
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    gap:19px;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center
}
.bin__b-navi{
    padding:16px 0;
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    gap:19px;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center;
    width:100%
}
.bin__b-inf{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    gap:19px;
    -webkit-box-flex:1;
    -ms-flex:1 1 auto;
    flex:1 1 auto;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center
}
._tab-active{
    color:rgba(254, 119, 1, 1);
    padding-bottom: 10px;
    margin-bottom: -10px;
}
._tab-active2{
    padding-left: -15px;
    border-bottom: 1px solid rgba(254, 119, 1, 1);
}
input[type=range]{
    -webkit-appearance:none;
    -moz-appearance:none;
    appearance:none;
    height:5px;
    outline:0
}
.bin__b-box{
    width:100%;
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    gap:40px
}
.bin .polov{
    width:50%
}
.bin__b-dostup{
    margin-bottom:13px;
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center;
    gap:20px
}
.bin__b-dostup p{
    color:#848e9c;
    font-family:Arial;
    font-size:16.067px;
    font-style:normal;
    font-weight:400;
    line-height:normal
}
.bin__b-scr{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center;
    gap:11px
}
.bin__b-scr span{
    color:#eaecef;
    text-align:right;
    font-family:Arial;
    font-size:14.728px;
    font-style:normal;
    font-weight:400;
    line-height:normal
}
.bin__form .button{
    border-radius:5.356px;
    background:#0ecb81;
    width:100%;
    padding:12px;
    text-align:center;
    color:#fff;
    text-align:center;
    font-family:Arial;
    font-size:17.406px;
    font-style:normal;
    font-weight:400;
    line-height:26.778px
}
.bin__form .button:hover{
    -webkit-filter:brightness(.9);
    filter:brightness(.9);
    -webkit-box-shadow:0 0 5px rgba(0,0,0,.3);
    box-shadow:0 0 5px rgba(0,0,0,.3)
}
.form-input{
    background:#292d33;
    border:1px solid transparent;
    border-radius:5px;
    color:#fff;
    color:#848e9c;
    font-family:Arial;
    font-size:17.406px;
    font-style:normal;
    font-weight:400;
    line-height:29.992px;
    padding:8px;
    -webkit-box-flex:1;
    -ms-flex:1 1 auto;
    flex:1 1 auto;
    margin-bottom:16px
}
.form-input input{
    background:0 0;
    color:#fff;
    text-align:right;
    width:100%;
    outline:0;
    color:#fff;
    color:#eaecef;
    text-align:right;
    font-family:Arial;
    font-size:18.745px;
    font-style:normal;
    font-weight:400;
    line-height:normal
}
.form-input__label{
    color:#848e9c;
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    font-size:10px;
    gap:10px;
    -webkit-box-pack:justify;
    -ms-flex-pack:justify;
    justify-content:space-between
}
.form-input__placeholder{
    color:#848e9c;
    font-family:Arial;
    font-size:17.406px;
    font-style:normal;
    -ms-flex-negative:0;
    flex-shrink:0;
    font-weight:400;
    line-height:29.992px
}
.form-input__currency{
    color:#eaecef;
    text-align:right;
    font-family:Arial;
    font-size:16.067px;
    font-style:normal;
    font-weight:400;
    line-height:29.992px;
    display: flex;
    align-items: center;
}

.amountin{
    margin-left: 3px;
    font-size: .75rem;
}
.input-range-form{
    margin-bottom:20px;
    width:100%
}
input[type=range]{
    -webkit-appearance:none;
    background:#474d57;
    cursor:pointer
}
input[type=range]::-webkit-slider-thumb{
    background:url(../img/thumb1.svg);
    -webkit-appearance:none;
    border:none;
    width:30px;
    background-repeat:no-repeat;
    height:30px
}
.polov2 input[type=range]::-webkit-slider-thumb{
    background:url(../img/thumb2.svg);
    -webkit-appearance:none;
    border:none;
    width:30px;
    background-repeat:no-repeat;
    height:30px
}
.polov2 .button{
    border-radius:5.356px;
    background:#f6465d
}
.bin__right{
    padding-left:21px
}
.bin__right .bin__c-percent{
    color:#b7bdc6;
    text-align:right;
    font-family:Arial;
    font-size:14.728px;
    font-style:normal;
    font-weight:400;
    line-height:26.778px
}
.bin__right .bin__c-name{
    color:#f6465d;
    font-family:Arial;
    font-size:14.728px;
    font-style:normal;
    font-weight:400;
    line-height:26.778px
}
.bin__right .bin__c-line{
    margin-bottom:4px
}
.show{
    display:block
}
.sitr{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center;
    -webkit-box-pack:justify;
    -ms-flex-pack:justify;
    justify-content:space-between;
    padding:5px 0;
    padding-top:10px
}
.sitr__name{
    color:#f6465d;
    font-family:Arial;
    font-size:25.439px;
    font-style:normal;
    font-weight:400;
    line-height:normal
}
.sitr__min{
    color:#848e9c;
    font-family:Arial;
    font-size:14.728px;
    font-style:normal;
    font-weight:400;
    line-height:normal
}
.sitr__ece{
    color:#848e9c;
    font-family:Arial;
    font-size:16.067px;
    font-style:normal;
    font-weight:400;
    line-height:normal;
    display:block;
    margin-left:auto
}
.account ._tab-active{
    border-radius:5px;
    color:rgba(254, 119, 1, 1)!important;
    background:#080808
}
.account{
    background:#080808
}
.account__container{
    padding-top:80px
}
.account__body{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    gap:30px;
    margin:0 auto;
    -webkit-box-pack:center;
    -ms-flex-pack:center;
    justify-content:center
}
.account__sidebar{
    padding:40px;
    border-radius:10px;
    background:#121318;
    max-width:432px;
    width:100%;
    padding-bottom:100px
}
.account__s-top img{
    border-radius:120px;
    background:#d8d8d8;
    margin-bottom:20px;
    display:block;
    margin-left:auto;
    margin-right:auto;
    max-width:125px;
    width:100%
}
.account__name{
    color:#fff;
    text-align:center;
    font-family:Inter;
    font-size:23px;
    font-style:normal;
    font-weight:500;
    line-height:28px
}
.account__date{
    margin-bottom:26px;
    color:#6c7080;
    text-align:center;
    font-family:Inter;
    font-size:16px;
    font-style:normal;
    font-weight:400;
    line-height:22px
}
.account__cont{
    width:100%;
    max-width:1180px
}
.account__box{
    width:100%
}
.account__b-top-left{
    width: 47%;
}
.account__b-top{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    padding:40px;
    border-radius:10px;
    width:100%;
    background:#121318;
    gap:130px;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center
}
.account__balans{
    margin-bottom:50px;
    color:rgba(254, 119, 1, 1);
    font-family:Inter;
    font-size:23px;
    font-style:normal;
    font-weight:500;
    line-height:28px
}
.account__balans-value{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center;
    gap:40px;
    color:#fff;
    font-family:Inter;
    font-size:55px;
    font-style:normal;
    font-weight:900;
    line-height:40px;
    letter-spacing:1.1px
}
.account__image{
    position:relative;
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center
}
.account__image img{
    position:absolute
}
.account .tabs__title{
    width:100%;
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    margin-bottom:10px;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center;
    gap:20px;
    padding:15px 20px;
    color:#6c7080;
    font-family:Inter;
    font-size:16px;
    font-style:normal;
    font-weight:400;
    line-height:22px
}
.account .tabs__title svg{
    fill:#9295a6
}
.account .tabs__title:hover{
    border-radius:5px;
    color:rgba(254, 119, 1, 1)!important;
    background:#080808
}
.account .tabs__title:hover svg{
    fill:rgba(254, 119, 1, 1)
}
.account ._tab-active svg{
    fill:rgba(254, 119, 1, 1)
}
.account__flex{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    width:100%;
    gap:40px;
    padding:37px 30px
}
.account__item{
    width:32%;
    padding:23px;
    border-radius:10px;
    background:#121318
}
.account__i-top{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center;
    gap:23px;
    color:#fff;
    color:#fff;
    font-family:Inter;
    font-size:20px;
    font-style:normal;
    -webkit-box-pack:center;
    -ms-flex-pack:center;
    justify-content:center;
    font-weight:700;
    line-height:normal;
    margin-bottom:24px
}
.account__i-value{
    color:#fff;
    font-family:Inter;
    font-size:30px;
    font-style:normal;
    font-weight:600;
    line-height:normal;
    letter-spacing:.5px;
    text-align:center
}
.account{
    padding-bottom:50px
}
.account__story{
    padding:40px;
    border-radius:10px;
    background:#121318
}
.account__s-title-big{
    color:#fff;
    font-family:Inter;
    font-size:23px;
    font-style:normal;
    font-weight:500;
    line-height:28px;
    margin-bottom:54px
}
.account__s-title{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center;
    gap:4px;
    color:#6c7080;
    font-family:Inter;
    font-size:14px;
    font-style:normal;
    font-weight:400;
    line-height:17px;
    width:100%;
    max-width:153px;
    text-align:right;
    display:flex;
    align-items:center
}
.account__s-title img{
    max-width:7px
}
.account__s-title span{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex
}
.account__s-title:first-child{
    width:100%;
    max-width:273px
}
.account__s-title:last-child{
    -webkit-box-pack:end;
    -ms-flex-pack:end;
    justify-content:flex-end
}
.account__s-title:nth-child(3){
    text-align:left
}
.account__ss-top{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    padding:20px 0;
    border-top:1px solid rgba(239,239,239,.15);
    border-bottom:1px solid rgba(239,239,239,.15);
    margin-bottom:20px;
    -webkit-box-pack:justify;
    -ms-flex-pack:justify;
    justify-content:space-between
}
.account__s-line{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center;
    margin-bottom:15px;
    padding:8px 0;
    -webkit-box-pack:justify;
    -ms-flex-pack:justify;
    justify-content:space-between
}
.account__s-name{
    width:273px;
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center;
    gap:8px;
    color:#fff;
    font-family:Inter;
    font-size:18px;
    font-style:normal;
    font-weight:400;
    line-height:24px
}
.account__s-make{
    width:153px;
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center;
    gap:8px;
    color:#fff;
    font-family:Inter;
    font-size:16px;
    font-style:normal;
    font-weight:400;
    line-height:22px
}
.account__s-id{
    width:153px;
    color:#6c7080;
    font-family:Inter;
    font-size:16px;
    font-style:normal;
    font-weight:400;
    line-height:22px
}
.account__s-summ{
    width:153px;
    color:#fff;
    font-family:Inter;
    font-size:16px;
    font-style:normal;
    font-weight:400;
    line-height:22px
}
.account__s-time{
    width:153px;
    color:#6c7080;
    font-family:Inter;
    font-size:16px;
    font-style:normal;
    font-weight:400;
    line-height:22px
}
.account__s-status{
    width:153px;
    text-align:right;
    font-family:Inter;
    font-size:16px;
    font-style:normal;
    font-weight:400;
    line-height:22px
}
.s-red2{
    padding:5px 8px;
    border-radius:4px;
    background:#080808;
    color:#f51825
}
.s-red{
    padding:5px 8px;
    border-radius:4px;
    background:#080808;
    color:rgba(254, 119, 1, 1)
}
.s-green{
    padding:5px 8px;
    border-radius:4px;
    background:#080808;
    color:#03a66d
}
.account__posts{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center;
    gap:20px;
    padding-top:20px;
    display:flex;
    align-items:center;
    gap:20px;
    margin-left:auto;
    color:#6c7080;
    text-align:center;
    -webkit-box-pack:end;
    -ms-flex-pack:end;
    justify-content:flex-end;
    font-family:Inter;
    font-size:16px;
    font-style:normal;
    font-weight:400;
    line-height:22px
}
.account__posts a{
    display:block;
    width:-webkit-fit-content;
    width:-moz-fit-content;
    width:fit-content;
    -webkit-transition:all .3s ease 0s;
    transition:all .3s ease 0s
}
.account__posts-btn{
    -webkit-transition:all .3s ease 0s;
    transition:all .3s ease 0s;
    display:block;
    width:-webkit-fit-content;
    width:-moz-fit-content;
    width:fit-content
}
.account__posts-nav{
    -webkit-transition:all .3s ease 0s;
    transition:all .3s ease 0s;
    display:block;
    width:-webkit-fit-content;
    width:-moz-fit-content;
    width:fit-content;
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    gap:20px;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center
}
.primary{
    color:#2b3144
}
.bin__orders .tabs__navigation{
    padding:20px 0;
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    gap:35px;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center;
    color:#848e9c;
    font-family:Arial;
    font-size:18.745px;
    font-style:normal;
    font-weight:400;
    line-height:21.423px
}
.bin__orders-top{
    width:100%;
    border-bottom:1.339px solid #252930;
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center;
    gap:60px;
    color:#848e9c;
    font-family:Arial;
    font-size:16.067px;
    font-style:normal;
    font-weight:400;
    line-height:normal;
    padding:12px 0
}
.bin__orders-name{
    width:120px;
    color:#848e9c;
    font-family:Arial;
    font-size:14.728px;
    font-style:normal;
    font-weight:400;
    line-height:normal
}
.bin__orders-name-x{
    color:rgba(254, 119, 1, 1);
    font-family:Arial;
    font-size:16.067px;
    font-style:normal;
    font-weight:400;
    line-height:normal;
    margin-left:auto
}
.bin__orders-body2{
    min-height:300px;
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-pack:center;
    -ms-flex-pack:center;
    justify-content:center;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center
}
.bin__orders-body2 p{
    color:#5e6673;
    text-align:center;
    font-family:Arial;
    font-size:15px;
    font-style:normal;
    font-weight:400;
    line-height:normal
}
.mil{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center;
    gap:21px;
    margin-bottom:14px
}
.non{
    display:block!important
}
.date__title{
    color:rgba(254, 119, 1, 1);
    font-family:Inter;
    font-size:23px;
    font-style:normal;
    font-weight:500;
    line-height:28px
}
.date__line{
    padding:50px 0;
    border-bottom:1px solid rgba(233,234,240,.15)
}
.date__l-title{
    margin-bottom:10px;
    color:#fff;
    font-family:Inter;
    font-size:16px;
    font-style:normal;
    font-weight:400;
    line-height:22px
}
.date__l-title-sub{
    margin-bottom:30px;
    color:#6c7080;
    font-family:Inter;
    font-size:14px;
    font-style:normal;
    font-weight:400;
    line-height:17px
}
.date__l-box{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    gap:30px;
    width:100%
}
.date__inp-wrap{
    width:100%;
    margin-bottom:20px
}
.date__inp-wrap p{
    margin-bottom:10px;
    display:block;
    color:#fff;
    font-family:Inter;
    font-size:14px;
    font-style:normal;
    font-weight:400;
    line-height:17px
}
.date__inp-wrap input{
    padding:14px 20px;
    border-radius:5px;
    display:block;
    width:100%;
    background:#080808;
    color:#9295a6;
    font-family:Inter;
    font-size:14px;
    font-style:normal;
    font-weight:400;
    line-height:17px
}
.vid{
    border-radius:10px;
    background:var(--dark-theme-section-color,#121318)
}
.date__bottom{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center;
    -webkit-box-pack:end;
    -ms-flex-pack:end;
    justify-content:flex-end;
    padding-top:30px;
    gap:30px
}
.date1{
    padding:19px 30px;
    border-radius:10px;
    background:#080808;
    color:rgba(254, 119, 1, 1);
    font-family:Inter;
    font-size:16px;
    font-style:normal;
    font-weight:400;
    line-height:22px;
    -webkit-transition:all .3s ease 0s;
    transition:all .3s ease 0s
}
.date1:hover{
    background:rgba(254, 119, 1, 1);
    color:#222
}
.date2{
    padding:19px 65px;
    border-radius:10px;
    background:rgba(254, 119, 1, 1);
    color:#222;
    text-align:center;
    font-family:Inter;
    font-size:16px;
    font-style:normal;
    font-weight:400;
    line-height:22px;
    -webkit-transition:all .3s ease 0s;
    transition:all .3s ease 0s
}
.date2:hover{
    background:#080808;
    color:rgba(254, 119, 1, 1)
}
.bin__orders-line{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    gap:60px;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center;
    width:100%;
    padding:17px 0;
    color:#fff;
    font-family:Arial;
    font-size:16.067px;
    font-style:normal;
    font-weight:400;
    line-height:normal
}
.bin__orders-line-name{
    width:120px;
    padding-left:5px;
    border-left:2.5px solid #0ecb81;
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center;
    font-family:Arial;
    font-size:16.067px;
    font-style:normal;
    align-items:center;
    font-weight:400;
    line-height:normal;
    text-transform:uppercase;
    gap:4px
}
.bin__orders-line-name p{
    color:#fff!important
}
.bin__orders-line-name span{
    color:rgba(254, 119, 1, 1);
    font-family:Arial;
    border-radius:5px;
    background:rgba(252,212,53,.1960784314);
    font-size:11px;
    padding:2px 3px;
    font-style:normal;
    font-weight:400;
    line-height:178.522%
}
.bin__orders-line-razmer{
    width:120px;
    color:#0ecb81;
    font-family:Arial;
    font-size:16.067px;
    font-style:normal;
    font-weight:400;
    line-height:normal
}
.bin__orders-line-cena{
    width:120px;
    color:#fff;
    font-family:Arial;
    font-size:16.067px;
    font-style:normal;
    font-weight:400;
    line-height:normal;
    letter-spacing:-.161px
}
.bin__orders-line-pnl{
    width:120px
}
.deps__rejected-text{
    width: 100%;
    text-align: center;
    font-size: 1.2rem;
    padding: 40px 0;
    border-radius: 15px;
    background: rgb(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;

}
.deposits__body{
    background:#121318;
    padding:40px;
    border-radius:10px
}
.deposits__wrap{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    gap:30px
}
.deposits__bar{
    width:50%;
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-orient:vertical;
    -webkit-box-direction:normal;
    -ms-flex-direction:column;
    flex-direction:column;
    gap:15px
}
.deposits__q1{
    cursor:pointer;
    padding:50px;
    border-radius:10px;
    background:#080808;
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-pack:center;
    -ms-flex-pack:center;
    justify-content:center;
    gap:17px;
    color:#fff;
    font-family:Inter;
    font-size:16px;
    font-style:normal;
    font-weight:400;
    line-height:22px;
    letter-spacing:.16px;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center
}
.deposits__q1:hover{
    background:rgba(254, 119, 1, 1)
}
.deposits__oplata{
    padding:20px;
    border-radius:10px;
    background:#080808
}
.deposits__oplata-line{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    align-items:center;
    justify-content: space-between;
    gap:25px;
    color:#6c7080;
    font-family:Inter;
    font-size:16px;
    font-style:normal;
    font-weight:400;
    line-height:22px;
    letter-spacing:.16px;
    margin-bottom:25px
}
.checkbox{
    display: flex;
    align-items: center;
}
.checkbox__input{
    margin-right: 5px;
}
.deposits__oplata-line .checkbox__input{
    cursor:pointer;
}
.deposits__oplata-line .checkbox__label{
    cursor:pointer
}
.deposits__summa{
    text-align: right;
    border-radius:5px;
    border:1px solid #6c7080;
    -webkit-box-flex:1;
    -ms-flex:1 1 auto;
    flex:1 1 auto;
    display:block;
    background:0 0;
    color:#6c7080;
    font-family:Inter;
    font-size:14px;
    font-style:normal;
    font-weight:400;
    line-height:22px;
    letter-spacing:.14px;
    padding:5px
}
.deposits__summa-custom{
    text-align: left;
    border-radius:5px;
    border:1px solid #6c7080;
    width: 150px;
    display:block;
    background:0 0;
    color:#6c7080;
    font-family:Inter;
    font-size:14px;
    font-style:normal;
    font-weight:400;
    line-height:22px;
    letter-spacing:.14px;
    padding:5px
}
.deposits__reqwi{
    border-radius:10px;
    background:#080808;
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-orient:vertical;
    -webkit-box-direction:normal;
    -ms-flex-direction:column;
    flex-direction:column;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center;
    padding:25px 35px;
    margin-bottom:10px;
    margin-top:10px;
    -webkit-box-flex:1;
    -ms-flex:1 1 auto;
    flex:1 1 auto
}
.deposits__reqwi-title{
    margin-bottom:22px;
    color:#fff;
    font-family:Inter;
    font-size:23px;
    font-style:normal;
    font-weight:500;
    line-height:28px;
    letter-spacing:.23px
}
.deposits__text{
    margin:19px 0;
    color:#6c7080;
    text-align:center;
    font-family:Inter;
    font-size:12px;
    font-style:normal;
    font-weight:400;
    line-height:18px;
    letter-spacing:.12px
}
.deposits__lion{
    border-radius:5px;
    padding:15px;
    border-radius:5px;
    border:1px solid rgba(204,206,217,.15);
    width:100%;
    color:#fff;
    font-family:Inter;
    font-size:16px;
    font-style:normal;
    font-weight:400;
    line-height:22px;
    letter-spacing:.32px;
    margin-bottom:10px
}
.deposits__lion2{
    border:1px solid rgba(204,206,217,.15);
    padding:15px;
    flex-direction: column;
    border-radius:5px;
    border:1px solid rgba(204,206,217,.15);
    width:100%;
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-pack:justify;
    -ms-flex-pack:justify;
    justify-content:space-between;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center;
    color:#fff;
    font-family:Inter;
    font-size:16px;
    font-style:normal;
    font-weight:400;
    line-height:22px;
    letter-spacing:.32px;
    margin-bottom:10px
}
.deposits__copy{
    width: 100%;
    padding:10px 20px;
    border-radius:4px;
    background:rgba(254, 119, 1, 1);
    color:#222;
    font-family:Inter;
    font-size:16px;
    font-style:normal;
    font-weight:400;
    line-height:22px;
    letter-spacing:.16px
}
.deposits__copy:hover{
    -webkit-filter:brightness(.9);
    filter:brightness(.9)
}
.sdkjfksdjfkn .MuiCircularProgress-svg{
    width: 20px;
    height: 20px;
    margin: 0;
}
.sdkjfksdjfkn{
    width: 20px !important;
    height: 20px !important;
}
.mr10{
    margin-right: 10px;
}

.deposits__btn{
    display: flex;
    justify-content:    center;
    align-items: center;
    border-radius:10px;
    margin-top: 15px;
    padding:10px 20px;

    background:rgba(254, 119, 1, 1);
    color:#222;
    font-family:Inter;
    font-size:16px;
    font-style:normal;
    font-weight:400;
    line-height:22px;
    letter-spacing:.16px;
    cursor:pointer;
    text-align:center
}
.deposits__btn:disabled {
    background: rgba(252, 213, 53, 0.8);
    cursor: not-allowed;
}
.deposits__btn:hover{
    -webkit-filter:brightness(.9);
    filter:brightness(.9)
}
.check-with-label:checked+.label-for-check{
    color:#fff
}
.perevod{
    border:1px solid rgba(204,206,217,.15);
    padding:15px;
    border-radius:5px;
    border:1px solid rgba(204,206,217,.15);
    width:100%;
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-pack:justify;
    -ms-flex-pack:justify;
    justify-content:space-between;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center;
    color:#fff;
    font-family:Inter;
    background:0 0;
    font-size:16px;
    font-style:normal;
    font-weight:400;
    line-height:22px;
    letter-spacing:.32px;
    margin-bottom:10px
}
.steps{
    margin-bottom:auto
}
.wallet__box{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    gap:30px;
    width:100%
}
.wallet__box .button{
    width:100%;
    padding:19px;
    border-radius:10px;
    background:rgba(254, 119, 1, 1);
    margin-top:50px;
    color:#222;
    text-align:center;
    font-family:Inter;
    font-size:18px;
    font-style:normal;
    font-weight:500;
    line-height:24px
}
.wallet__item{
    padding:40px;
    border-radius:10px;
    background:#121318;
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    width:100%;
    max-width:532px;
    -webkit-box-orient:vertical;
    -webkit-box-direction:normal;
    -ms-flex-direction:column;
    flex-direction:column;
    -webkit-box-pack:justify;
    -ms-flex-pack:justify;
    justify-content:space-between;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center
}
.wallet__item .select{
    color:#fff;
    font-family:Inter;
    font-size:16px;
    font-style:normal;
    font-weight:500;
    line-height:24px;
    background:0 0;
    border:none;
    outline:0;
    width:100%
}
.wallet__item option{
    background:0 0;
    color:#080808
}
.wallet__title{
    margin-bottom:40px;
    color:rgba(254, 119, 1, 1);
    font-family:Inter;
    font-size:23px;
    font-style:normal;
    font-weight:500;
    line-height:104.348%
}
.wallet__block{
    width:100%;
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-orient:vertical;
    -webkit-box-direction:normal;
    -ms-flex-direction:column;
    flex-direction:column;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center;
    gap:10px
}
.wallet__line{
    border-radius:5px;
    border:1px solid rgba(204,206,217,.15);
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    width:100%;
    -webkit-box-pack:justify;
    -ms-flex-pack:justify;
    justify-content:space-between;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center;
    gap:20px;
    padding:10px
}
.wallet__line-left{
    width:100%
}
.wallet__right{
    color:#fff;
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center;
    font-family:Inter;
    font-size:14px;
    font-style:normal;
    font-weight:400;
    line-height:17px
}
.wallet__right .select2{
    font-family:Inter;
    font-size:12px;
    font-style:normal;
    font-weight:400;
    line-height:17px;
    background:0 0;
    margin-left:10px;
    color:#fff;
    width:-webkit-fit-content;
    width:-moz-fit-content;
    width:fit-content;
    outline:0
}
.wallet__bom{
    margin-top:40px;
    width:100%
}
.wallet__txt{
    margin-bottom:10px;
    color: rgba(254, 119, 1, 1);
    font-family:Inter;
    font-size:14px;
    font-style:normal;
    font-weight:400;
    line-height:17px
}
.wallet__bomm{
    border-radius:5px;
    border:1px solid rgba(204,206,217,.15);
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    width:100%;
    -webkit-box-pack:justify;
    -ms-flex-pack:justify;
    justify-content:space-between;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center;
    gap:20px;
    padding:10px
}
.wallet__txt2{
    color:#848e9c;
    font-family:Inter;
    font-size:12px;
    font-style:normal;
    font-weight:400;
    line-height:22px;
    letter-spacing:.12px
}
.wallet__value{
    background:0 0;
    color:#fff;
    font-family:Inter;
    font-size:16px;
    font-style:normal;
    font-weight:500;
    line-height:24px
}
.bom2{
    margin-top:0
}
.kyc__box{
    padding:40px;
    border-radius:10px;
    background:#121318;
    height:100%
}
.kyc__tip .select1{
    width:100%;
    max-width:225px;
    margin-bottom:30px;
    border-radius:5px;
    background:#080808;
    padding:15px 20px;
    color:#6c7080;
    font-family:Inter;
    font-size:16px;
    font-style:normal;
    font-weight:400;
    line-height:22px
}
.kyc__inputs{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    gap:30px;
    width:100%;
    border-bottom:1px solid rgba(233,234,240,.15)
}
.kyc__item{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-orient:vertical;
    -webkit-box-direction:normal;
    -ms-flex-direction:column;
    flex-direction:column;
    gap:10px;
    width:30%
}
.kyc__item:last-child{
    width:70%
}
.kyc__item p{
    color:#6c7080;
    font-family:Inter;
    font-size:14px;
    font-style:normal;
    font-weight:400;
    line-height:17px
}
.kyc__item input{
    padding:14px 20px;
    border-radius:5px;
    background:#080808;
    color:#fff;
    font-family:Inter;
    font-size:14px;
    font-style:normal;
    font-weight:400;
    line-height:17px
}
.kyc__item input:focus{
    border:1px solid rgba(254, 119, 1, 1)
}
.kyc__item input:active{
    border:1px solid rgba(254, 119, 1, 1)
}
.kyc__title2{
    margin-bottom:30px;
    color:#fff;
    font-family:Inter;
    font-size:16px;
    font-style:normal;
    font-weight:400;
    line-height:22px
}
.kyc__box2{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    gap:30px;
    padding-bottom:40px
}
.kyc__itemm{
    width:32%
}
.kyc__b-item{
    height:100%;
    border-radius:10px;
    border:1.5px dashed rgba(204,206,217,.15);
    padding:30px 0;
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-orient:vertical;
    -webkit-box-direction:normal;
    -ms-flex-direction:column;
    flex-direction:column;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center;
    -webkit-box-pack:center;
    -ms-flex-pack:center;
    justify-content:center;
    cursor:pointer;
    position:relative;
    -webkit-transition:all .3s ease 0s;
    transition:all .3s ease 0s
}
.kyc__b-item:hover{
    background:#080808
}
.kyc__b-item input{
    position:absolute;
    width:100%;
    height:100%;
    display:block;
    font-size:0;
    cursor:pointer;
    opacity:0;
    left:0;
    top:0;
    z-index:12
}
.kyc__name{
    margin-bottom:8px;
    color:#fff;
    text-align:center;
    font-family:Inter;
    font-size:16px;
    font-style:normal;
    font-weight:400;
    line-height:22px
}
.kyc__name span{
    color:rgba(254, 119, 1, 1)
}
.kyc__sub{
    color:#9295a6;
    font-family:Inter;
    font-size:12px;
    font-style:normal;
    font-weight:400;
    line-height:18px;
    letter-spacing:.12px
}
.malina{
    margin-bottom:20px;
    color:#6c7080;
    font-family:Inter;
    font-size:14px;
    font-style:normal;
    font-weight:400;
    line-height:17px
}
.btn56{
    padding:19px 65px;
    border-radius:10px;
    background:rgba(254, 119, 1, 1);
    color:#222;
    text-align:center;
    font-family:Inter;
    font-size:16px;
    font-style:normal;
    font-weight:400;
    line-height:22px;
    max-width:215px;
    -webkit-transition:all .3s ease 0s;
    transition:all .3s ease 0s;
    display:block;
    margin-left:auto
}
.btn56:hover{
    -webkit-filter:brightness(.8);
    filter:brightness(.8)
}
.lineeee{
    margin-top:30px;
    background:rgba(233,234,240,.15);
    height:1px;
    margin-bottom:30px;
    width:100%
}
.tpsl{
    display:none
}
.show{
    display:block!important
}
.show2 .deposits__q1{
    background:rgba(254, 119, 1, 1)
}
input[type=radio]:after{
    width:15px;
    height:15px;
    border-radius:15px;
    top:-2px;
    left:-1px;
    position:relative;
    background-color:transparent;
    content:"";
    display:inline-block;
    visibility:visible;
    border:2px solid #fff
}
input[type=radio]:checked:after{
    width:15px;
    height:15px;
    border-radius:15px;
    top:-2px;
    left:-1px;
    position:relative;
    background-color:orange;
    content:"";
    display:inline-block;
    visibility:visible;
    border:2px solid #fff
}
.wallet__line{
    cursor:pointer
}
.wallet__right .select2{
    max-width:50px
}
.story-orders__box .tabs__navigation{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    color:#6c7080;
    font-family:Inter;
    gap:35px;
    font-size:16px;
    font-style:normal;
    font-weight:400;
    line-height:22px
}
.story-orders__box .tabs__title{
    padding:0;
    padding-bottom:30px;
    margin:0;
    background:0 0!important;
    border:none;
    width:-webkit-fit-content;
    width:-moz-fit-content;
    width:fit-content
}
.story-orders__box .tabs__title._tab-active{
    border-bottom:1px solid rgba(254, 119, 1, 1);
    background:0 0;
    border-radius:0
}
.story-orders__top-bar{
    padding:20px 0;
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center;
    width:100%;
    -webkit-box-pack:justify;
    -ms-flex-pack:justify;
    justify-content:space-between;
    margin-bottom:36px;
    border-top:1px solid rgba(239,239,239,.15);
    border-bottom:1px solid rgba(239,239,239,.15)
}
.story-orders__top-item{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center;
    color:#6c7080;
    font-family:Inter;
    font-size:14px;
    font-style:normal;
    font-weight:400;
    line-height:17px;
    width:125px
}
.story-orders__top-item:first-child{
    width:140px
}
.story-orders__top-item:nth-child(5){
    width:140px
}
.story-orders__top-item:nth-child(6){
    padding-left: 0
}
.story-orders__line{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-pack:justify;
    -ms-flex-pack:justify;
    justify-content:space-between;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center;
    margin-bottom:30px
}
.story-orders__line .bin__orders-line-name{
    padding-left:0;
    border-left:none;
    width:140px
}
.story-orders__line-storona{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    gap:25px;
    width:125px;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center
}
.story-orders__storona-value{
    color:#fff;
    font-family:Inter;
    font-size:16px;
    font-style:normal;
    font-weight:400;
    line-height:137.5%
}
.story-orders__storona-data{
    color:#6c7080;
    font-family:Inter;
    font-size:10px;
    font-style:normal;
    font-weight:400;
    line-height:14px
}
.revert{
    cursor: pointer;
}
.story-orders__line-item{
    width:123px;
    color:#fff;
    font-family:Inter;
    font-size:16px;
    font-style:normal;
    font-weight:400;
    line-height:137.5%
}
.story-orders__line-item:nth-child(5){
    width:140px
}
.account__sidebar{
    min-width:275px
}
.AlertOrders{
    color: rgb(134, 134, 134);
}

.deposits__summa{
    opacity:1;
    max-width:50%;
    -webkit-box-flex:1;
    -ms-flex:1 1 auto;
    flex:1 1 auto
}
.show3{
    opacity:1!important
}
.account__balans-value{
    cursor:pointer
}
#faPlus{
    display:none
}
.swiper{
    overflow:hidden
}
.swiper-wrapper{
    width:100%;
    height:100%;
    -webkit-box-sizing:content-box;
    box-sizing:content-box;
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    position:relative
}
.swiper-vertical .swiper-wrapper{
    -webkit-box-orient:vertical;
    -webkit-box-direction:normal;
    -ms-flex-direction:column;
    flex-direction:column
}
.swiper-autoheight .swiper-wrapper{
    -webkit-box-align:start;
    -ms-flex-align:start;
    align-items:flex-start
}
.swiper-initialized .swiper-slide{
    -ms-flex-negative:0;
    flex-shrink:0
}
.swiper-android .swiper-slide,.swiper-android .swiper-wrapper{
    -webkit-transform:translate3d(0,0,0);
    transform:translate3d(0,0,0)
}
.swiper-button-lock{
    display:none!important
}
@media (min-width:121.875em){
    .date__title{
        margin-bottom:1.875rem
    }
    .vid{
        padding:2.5rem
    }
    .kyc__inputs{
        padding-bottom:3.125rem
    }
    .kyc__inputs{
        margin-bottom:3.125rem
    }
}
@media (max-width:100em){
    .bin__orders-top{
        gap:30px;
        font-size:13px
    }
    .bin__orders-name{
        font-size:14px;
        width:100px
    }
    .bin__orders-name-x{
        font-size:14px
    }
    .bin__orders-line{
        padding:15px 0;
        gap:30px
    }
    .bin__orders-line-name span{
        font-size:10px
    }
    .bin__orders-line-name{
        font-size:14px;
        width:100px
    }
    .bin__orders-line-razmer{
        font-size:14px;
        width:100px
    }
    .bin__orders-line-cena{
        font-size:14px;
        width:100px
    }
    .story-orders__storona-value{
        font-size:14px
    }
    .account__sidebar{
        zoom:80%
    }
    .account__cont{
        zoom:85%
    }
    .deposits__oplata-line{
        font-size:14px
    }
}
@media (max-width:90em){
    .sitr__name{
        font-size:20px
    }
}
@media (max-width:20em){
    .date__title{
        margin-bottom:.625rem
    }
    .vid{
        padding:1.25rem
    }
    .kyc__inputs{
        padding-bottom:1.5625rem
    }
    .kyc__inputs{
        margin-bottom:1.5625rem
    }
}
@media (max-width:112.5em){
    .bin__right{
        max-width:340px;
        padding-left:10px!important
    }
    .bin__w-left{
        max-width:340px;
        padding:11px;
        padding-left:0
    }
}
@media (max-width:93.75em){
    .account__sidebar{
        padding:20px
    }
    .account__b-top{
        padding:20px
    }
    .story-orders__box{
        zoom:88%
    }
}
@media (max-width:90.625em){
    .account__cont{
        max-width:1000px
    }
    .account__flex{
        gap:30px
    }
    .account__item{
        padding:15px
    }
    .account__i-top{
        font-size:18px
    }
    .account__i-value{
        font-size:20px;
        line-height:150%
    }
    .account__s-title:first-child{
        width:230px
    }
    .account__s-title:last-child{
        width:100px
    }
    .account__s-name{
        width:170px
    }
    .account__s-summ{
        width:100px
    }
    .account__s-status{
        width:120px
    }
}
@media (max-width:83.75em){
    .bin__w-grafik{
        min-width:650px
    }
}
@media (max-width:81.25em){
    .account__cont{
        zoom:70%
    }
}
@media (min-width:20em) and (max-width:121.875em){
    .date__title{
        margin-bottom:calc(.625rem + 1.25 * (((100vw - 20rem))/ ((101.875))))
    }
    .vid{
        padding:calc(1.25rem + 1.25 * (((100vw - 20rem))/ ((101.875))))
    }
    .kyc__inputs{
        padding-bottom:calc(1.5625rem + 1.5625 * (((100vw - 20rem))/ ((101.875))))
    }
    .kyc__inputs{
        margin-bottom:calc(1.5625rem + 1.5625 * (((100vw - 20rem))/ ((101.875))))
    }
}
